import { $fetch, Headers } from 'ofetch';
import { isObjectLiteral, snake, transformKeys } from '@upfrontjs/framework';
import { getCSRFToken, resetCSRFToken } from '~/utils/csrfToken';

export interface PaginatedApiResponse<T> {
    data: T[];
    links: {
        first: string;
        last: string;
        prev: string | null;
        next: string | null;
    };
    meta: {
        currentPage: number;
        /**
         * From all the existing records, this is where the current items start from.
         */
        from: number | null;
        /**
         * From all the existing records, this is where the current items go to.
         */
        to: number | null;
        lastPage: number;
        perPage: number;
        links: {
            url: string | null;
            label: string;
            active: boolean;
        }[];
        /**
         * Total number of records.
         */
        total: number;
        /**
         * Base path of the request.
         */
        path: string;
    };
}

const isPaginatedResponse = <T>(response: unknown): response is PaginatedApiResponse<T> => {
    return isObjectLiteral(response) && 'meta' in response && 'links' in response;
};

const apiFetch = $fetch.create({
    credentials: 'include',
    cache: 'default',
    // todo - this would ideally come from the public runtime config
    baseURL: import.meta.env.VITE_API_URL || process.env.VITE_API_URL,
    onRequest: ({ options }): Promise<void> | void => {
        if (!(options.headers instanceof Headers)) {
            options.headers = new Headers();
        }

        const token = getCSRFToken();

        if (token) {
            options.headers.set('X-XSRF-TOKEN', token);
        }

        if (options.body instanceof FormData) {
            for (const [key, value] of options.body.entries()) {
                options.body.delete(key);
                options.body.set(snake(key), value);
            }
        } else {
            options.headers.set('Content-Type', 'application/json; charset="utf-8"');

            if (isObjectLiteral(options.body)) {
                options.body = transformKeys(options.body, 'snake');
            }
        }

        options.headers.set('Accept', 'application/json');
    },
    parseResponse: body => {
        if (!body) return body;

        const parsed = JSON.parse(body);

        if (isPaginatedResponse(parsed)) {
            return {
                links: parsed.links,
                meta: transformKeys(parsed.meta),
                data: parsed.data.map(entry => {
                    return Array.isArray(entry)
                        ? entry.map(val => transformKeys(val as Record<string, any>))
                        : transformKeys(entry as Record<string, any>);
                })
            };
        }

        const data = isObjectLiteral(parsed) && 'data' in parsed ? parsed.data : parsed;

        // eslint-disable-next-line no-mixed-operators
        if (isObjectLiteral(data) || Array.isArray(data)) {
            return Array.isArray(data)
                ? data.map(val => transformKeys(val as Record<string, any>))
                : transformKeys(data);
        }

        return data;
    },
    onRequestError: context => {
        if (import.meta.server) {
            // eslint-disable-next-line no-console
            console.trace(context.error);
            return;
        }

        if (!context.response) {
            return;
        }

        // missing or mismatching csrf token
        if (context.response.status === 419) {
            void resetCSRFToken();
        }
    }
});

export default apiFetch;
