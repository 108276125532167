<template>
    <div class="flex flex-col">
        <h1 class="mb-6 flex items-center justify-center gap-4 text-center">
            {{ name }}
        </h1>
        <ImageUploader class="mb-12" @uploaded="retain" />

        <TransitionExpand>
            <div v-show="images.length">
                <Sortable :list="images"
                          tag="div"
                          :options="{ animation: 150, group: 'images' }"
                          class="flex flex-row flex-wrap items-start justify-evenly gap-3"
                          item-key="id"
                          @end="updateOrder">
                    <template #item="{ element }">
                        <Transition appear name="list">
                            <div v-if="!element.transitionOut"
                                 class="indicator block size-80 max-h-80 max-w-80 2xl:size-96 2xl:max-h-96 2xl:max-w-96">
                                <div class="aspect-1/1 indicator-item">
                                    <button class="btn btn-circle btn-error btn-xs"
                                            @click="deleteImage(element.id)">
                                        <Icon name="close" size="16" />
                                    </button>
                                </div>
                                <span class="badge indicator-item badge-secondary
                                                 indicator-center indicator-middle">
                                    {{ element.meta.order }}
                                </span>
                                <div :class="{ 'ring': selectedImage?.id === element.id }"
                                     class="size-full cursor-pointer overflow-hidden rounded-lg ring-primary ring-offset-2 ring-offset-base-100"
                                     @click="selectImage(element)">
                                    <NuxtPicture :src="element.source"
                                                 loading="lazy"
                                                 provider="weserve"
                                                 sizes="300px sm:700px"
                                                 class="object-cover object-center" />
                                </div>
                            </div>
                        </Transition>
                    </template>
                </Sortable>
            </div>
        </TransitionExpand>

        <TransitionSlide :offset="[0, 16]">
            <form v-show="selectedImage"
                  class="fixed bottom-0 left-0 z-10 w-full space-y-2 rounded bg-base-200/75 p-4 shadow backdrop-blur-sm
                         xs:bottom-5 xs:left-auto xs:right-5 xs:w-auto"
                  @submit.prevent="saveCost">
                <label class="input input-bordered flex items-center gap-2">
                    £
                    <input v-model="newCost"
                           required
                           class="grow"
                           min="0"
                           step=".01"
                           pattern="^\d*(\.\d{0,2})?$"
                           placeholder="Cost of this cake"
                           type="number">
                </label>
                <div class="flex flex-row items-center justify-between gap-2">
                    <button class="btn btn-outline btn-sm" @click.prevent="selectedImage = undefined">
                        Cancel
                    </button>
                    <button class="btn btn-primary" :disabled="canSaveCost">
                        Save
                    </button>
                </div>
            </form>
        </TransitionSlide>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ImageUploader from '~/components/admin/ImageUploader.vue';
import apiFetch from '~/utils/apiFetch';
import type { ImageAttributes } from '~/types';
import useGalleries from '~/composables/useGalleries';
import type { SortableEvent } from 'sortablejs';
import { useToast } from 'vue-toastify';
import type { GalleryAttributes } from '~/types';
import { Sortable } from 'sortablejs-vue3';
import parseError from '~/utils/parseError';
import { helpers } from '@typed-router';

export default defineComponent({
    name: 'Edit',

    components: {
        ImageUploader,
        Sortable
    },

    setup: async () => {
        const galleries = useGalleries();
        const router = useRouter();

        if (galleries.value.length === 0) {
            galleries.value = await apiFetch<GalleryAttributes[]>('galleries');
        }

        definePageMeta({
            middleware: [
                'auth',
                (to) => {
                    const galleries = useGalleries();
                    const id = Number(to.params.id);

                    if (galleries.value.length === 0 || !galleries.value.find(gallery => gallery.id === id)) {
                        return helpers.route({ name: 'admin-galleries' });
                    }
                }
            ]
        });

        const galleryId = computed(() => Number((router.currentRoute.value.params as { id: string }).id));
        const gallery = computed(() => galleries.value.find(gallery => gallery.id === galleryId.value)!);
        const images = ref([...gallery.value.images]);
        const toast = useToast();
        const loader = useLoader();
        const orderChanged = computed(() => {
            return images.value
                .map((image, index) => image.id === gallery.value.images[index].id)
                .includes(false);
        });
        const selectedImage = ref<ImageAttributes>();
        const newCost = ref<number>();
        const canSaveCost = computed(() => !newCost.value || newCost.value === selectedImage.value?.meta.cost);

        const retain = async (files: { location: string; width: number; height: number; size: number }[]) => {
            loader?.on();
            await Promise.all(files.map(async file => {
                return apiFetch<ImageAttributes>(
                    `/galleries/${gallery.value.id}/images`,
                    {
                        method: 'POST',
                        body: file
                    }
                )
                    .then(image => {
                        galleries.value = galleries.value
                            .map(p => {
                                if (p.id === gallery.value.id) {
                                    p.images.push(image);
                                }

                                return p;
                            });

                        // ensure the images are kept up to date
                        images.value = gallery.value.images;

                        return;
                    });
            }))
                .catch(reason => {
                    toast.error(parseError(reason).errorMessage);
                    loader?.off();
                })
                .finally(loader?.off);
        };
        const updateOrder = async (event: SortableEvent) => {
            const image = images.value[event.oldIndex!];
            images.value.splice(event.oldIndex!, 1);
            images.value.splice(event.newIndex!, 0, image);

            if (!orderChanged.value) {
                return;
            }

            await apiFetch<ImageAttributes[]>(
                `/galleries/${gallery.value.id}/images/order`,
                {
                    method: 'POST',
                    body: {
                        images: images.value
                            .map((image, index) => ({
                                id: image.id,
                                // given the order is descending, we need to reverse it
                                order: images.value.length - index
                            }))
                            // get the images only where the order has changed
                            .filter((image, index) => image.id !== gallery.value.images[index].id)
                    }
                })
                .then(imgs => {
                    galleries.value = galleries.value
                        .map(p => {
                            if (p.id === gallery.value.id) {
                                p.images = imgs;
                            }

                            return p;
                        });

                    images.value = [...imgs];
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };
        const deleteImage = async (imageId: ImageAttributes['id']) => {
            await apiFetch(`galleries/${gallery.value.id}/images`, {
                method: 'DELETE',
                body: { imageId }
            })
                .catch(reason => {
                    toast.error(parseError(reason).errorMessage);
                    throw reason;
                });

            if (selectedImage.value?.id === imageId) {
                selectedImage.value = undefined;
            }

            // remove the image from the product in the store
            galleries.value = galleries.value
                .map(p => {
                    if (p.id === gallery.value.id) {
                        p.images = p.images.filter(i => i.id !== imageId);
                    }

                    return p;
                });

            // ensure the images are kept up to date from the store
            images.value = gallery.value.images;
        };
        const saveCost = async () => {
            if (!selectedImage.value) return;

            const loaderId = useToast().loader('Updating cost');
            const updatedImage = await apiFetch(
                `images/${selectedImage.value.id!}`,
                {
                    method: 'PATCH',
                    body: { meta: { cost: newCost.value } }
                }
            )
                .finally(() =>  useToast().stopLoader(loaderId));

            useToast().success('Cost updated successfully');
            selectedImage.value.meta.cost = updatedImage.meta.cost;
            images.value = images.value.map(image => {
                return image.id === selectedImage.value!.id ? updatedImage : image;
            });
        };
        const selectImage = (image: ImageAttributes) => {
            selectedImage.value = image;
            newCost.value = image.meta.cost;
        };

        return {
            retain,
            images,
            name: gallery.value.name,
            updateOrder,
            deleteImage,
            selectedImage,
            newCost,
            saveCost,
            selectImage,
            canSaveCost
        };
    }
});
</script>
