import type { InjectionKey, Ref } from 'vue';
import type { IngredientEditorMethods, LoaderMethods } from '~/types';

export const loaderKey: InjectionKey<Ref<LoaderMethods | undefined>> = Symbol('loader');
export const ingredientEditorKey: InjectionKey<Ref<IngredientEditorMethods | undefined>> = Symbol('ingredientEditor');

export const qAndA: { question: string; answer: string }[] = [
    {
        question: 'What sizes are available?',
        answer: 'Sizes are visible on each product page. However, if you require a different size, please get' +
            ' in touch.'
    },
    {
        question: 'What flavours are available?',
        answer: 'The most popular cakes are chocolate and vanilla and their fruit variations, Biscoff, Dobos,' +
            ' lemon, and black forest cake. However, you may order any flavours.'
    },
    {
        question: 'Do you offer delivery?',
        answer: 'I do! £5 within 10 mile radius of Horsford. For further distances, please contact me.'
    },
    {
        question: 'Do you offer free from cakes?',
        answer: 'Unfortunately, I do not offer free from cakes at the moment.'
    },
    {
        question: 'How much notice do you need for an order?',
        answer: 'I require at least 2 weeks notice for all orders. However, I may be able to accommodate ' +
            'shorter notice orders. Please contact me to discuss.'
    },
    {
        question: 'Do you make shaped cakes?',
        answer: 'Unfortunately, I do not make shaped cakes at the moment.'
    },
    {
        question: 'Do you cater for events?',
        answer: 'Yes, I do! Be it a birthday, wedding or any other event. Please contact me to discuss your ' +
            'requirements.'
    },
    {
        question: 'Is there a maximum order size?',
        answer: 'Depends on capacity. Please get in touch to discuss your requirements.'
    },
    {
        question: 'Do you require a deposit?',
        answer: 'Yes, I require a deposit to secure your order. The amount is dependent on the order size. ' +
            'The remaining balance is due on collection or before delivery.'
    }
];

export const title = 'Cina\'s Delicious Cakes';
export const description = 'Cina\'s Delicious Cakes is a home-based cake business in Horsford, Norwich. ' +
    'I make cakes for all occasions, including birthdays, weddings, and other events.';

export const locations = [
    'Horsford',
    'Hellesdon',
    'Norwich',
    'Spixworth',
    'Norfolk',
    'Old Catton',
    'Horsham St Faith',
    'Taverham',
    'Drayton'
];
