import type { FetchError } from 'ofetch';
import type { NuxtError } from '#app';

interface ErrorShape {
    message: string;
    errors: Record<string, string[]>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const parseError = (error: FetchError & ErrorShape | NuxtError) => {
    return {
        get code() {
            if ('status' in error) {
                return error.status!;
            }

            return error.statusCode ?? -1;
        },

        get isUnauthenticatedError() {
            return this.code === 401;
        },

        get isNotFoundError() {
            return this.code === 404;
        },

        get isValidationError() {
            return this.code === 422;
        },

        get isServerError() {
            return this.code >= 500;
        },

        get isClientError() {
            return this.code >= 400 && this.code < 500;
        },

        get errorMessage() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return error.data.message;
        }
    };
};

export default parseError;
