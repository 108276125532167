import { defineStore } from 'pinia';
import type { ProductAttributes, ProductVariationAttributes, IngredientAttributes } from '~/types';
import { watch } from 'vue';
import type { PaginatedApiResponse } from '~/utils/apiFetch';
import apiFetch from '~/utils/apiFetch';
import variationProjectedCost from '~/utils/variationProjectedCost';
import useAuthStore from '~/stores/auth';
import useIngredientStore from '~/stores/ingredient';
import type { ProductType } from '~/composables/useProductTypes';

interface State {
    /**
     * The loaded products.
     */
    products: ProductAttributes[];

    /**
     * The id of the product currently viewed.
     */
    productId: ProductAttributes['id'] | null;

    draftProduct: ProductAttributes | null;
    productsLoading: boolean;
    productsLoaded: boolean;
}

const useProductStore = defineStore('products', {
    state: (): State => {
        return {
            products: [],
            productId: null,
            draftProduct: null,
            productsLoading: false,
            productsLoaded: false
        };
    },
    getters: {
        /**
         * The currently viewed product.
         */
        product: (state): Readonly<ProductAttributes> | null => {
            return state.products.find(p => p.id === Number(state.productId)) ?? null;
        },

        /**
         * The products that have at least one undervalued variation.
         *
         * @param state
         */
        underValuedProducts: (state): readonly ProductAttributes[] => {
            const authStore = useAuthStore();

            if (!authStore.isAuthenticated || state.productsLoading) {
                return [];
            }

            const ingredientStore = useIngredientStore();

            return state.products.filter((product: ProductAttributes) => {
                return product.variations
                    .filter(variation => {
                        return variation.cost && variationProjectedCost(
                            variation,
                            authStore.user!.hourlyRate,
                            authStore.user!.utilityCosts,
                            ingredientStore.ingredients
                        ) > variation.cost;
                    })
                    .length > 0;
            });
        },

        /**
         * The productTypes that are used by the products.
         */
        productTypes: (state): ProductType[] => {
            return [...new Set(state.products.map(product => product.type))];
        }
    },
    actions: {
        async loadProducts(force = false): Promise<void> {
            if (this.productsLoading) {
                // wait for an existing load to finish
                return new Promise(resolve => {
                    const stopWatch = watch(() => this.productsLoading, loading => {
                        if (!loading) {
                            resolve(void 0);
                            stopWatch();
                        }
                    });
                });
            }

            if (this.productsLoaded && !force) {
                return;
            }

            this.productsLoading = true;
            const products: ProductAttributes[] = [];

            const promises = await apiFetch<PaginatedApiResponse<ProductAttributes>>('/products')
                .then(paginator => {
                    products.push(...paginator.data);


                    const promises: Promise<unknown>[] = [];

                    for (let i = 2; i <= paginator.meta.lastPage; i++) {
                        promises.push(
                            apiFetch<PaginatedApiResponse<ProductAttributes>>(`/products?page=${i}`)
                                // eslint-disable-next-line promise/no-nesting
                                .then(paginator => products.push(...paginator.data))
                        );
                    }

                    return promises;
                });

            await Promise.all(promises).finally(() => this.productsLoading = false);
            products.forEach(product => {
                // ensure images are sorted by order
                product.images.sort((a, b) => a.meta.order - b.meta.order);

                // ensure variations are sorted by cost (ascending)
                product.variations.sort((a, b) => {
                    return a.cost
                        ? b.cost
                            ? a.cost - b.cost
                            : -1
                        : 1;
                });
            });
            products.sort(
                (a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true, sensitivity: 'accent' })
            );
            this.products = products;
            this.productsLoaded = true;
        },

        /**
         * Load product attributes that are only available when logged in.
         *
         * @param id
         */
        async ensureFullyLoaded(id: ProductAttributes['id'] | ProductAttributes['slug']): Promise<void> {
            // if no products are loaded, load them
            if (!this.productsLoaded) {
                await this.loadProducts();
            }

            // if products are still loading, wait for them to finish
            if (this.productsLoading) {
                await new Promise(resolve => {
                    const interval = setInterval(() => {
                        if (!this.productsLoading) {
                            clearInterval(interval);
                            resolve(void 0);
                        }
                    }, 100);
                });
            }

            const idIsSlug = isNaN(Number(id));
            const product = this.products.find(p => (idIsSlug ? p.slug : p.id) === id);

            if (!product) {
                throw createError({
                    statusCode: 404,
                    statusMessage: 'Not Found',
                    message: 'Product Not Found.',
                    fatal: false
                });
            }

            const fullyLoaded = ['preparation_time', 'instructions', 'in_draft']
                .every(attribute => product[attribute] !== undefined);

            if (!fullyLoaded) {
                const data = await apiFetch<ProductAttributes>(`products/${id}`);
                this.products = this.products.map(p => {
                    if (p.id !== id) {
                        return p;
                    }

                    data.variations = data.variations.map(
                        (variation: ProductVariationAttributes & { ingredients?: IngredientAttributes[] }) => {
                            if (!variation.ingredients) {
                                return variation;
                            }

                            // if returning ingredients, sort it into the correct shape
                            variation.ingredientsAmounts = variation.ingredients.map(i => ({
                                amount: i.ingredientProductAmount!,
                                ingredientId: i.id
                            }));

                            return variation;
                        });

                    return data;
                });
            }
        }
    }
});

export default useProductStore;
