<template>
    <div>
        <div class="grid grid-cols-1 gap-3 align-middle md:grid-cols-2 xl:grid-cols-3">
            <UndervaluedProducts />
            <MostUsedIngredients v-if="data" :ingredients="data.mostUsedIngredients" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UndervaluedProducts from '~/components/admin/dashboard-widgets/UndervaluedProducts.vue';
import { useAsyncData } from '#imports';
import apiFetch from '~/utils/apiFetch';
import type { IngredientAttributes } from '~/types';
import MostUsedIngredients from '~/components/admin/dashboard-widgets/MostUsedIngredients.vue';

interface WidgetInformation {
    mostUsedIngredients: Required<IngredientAttributes>[];
}

export default defineComponent({
    name: 'Index',

    components: { MostUsedIngredients, UndervaluedProducts },

    setup: async () => {
        definePageMeta({ middleware: 'auth' });

        const { data } = await useAsyncData(
            'widget-information',
            async () => apiFetch<WidgetInformation>('/widget-information')
        );

        return {
            data
        };
    }
});
</script>
