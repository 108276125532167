import validate from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import gallery_45loaded_45global from "/home/runner/work/cdc-web/cdc-web/src/middleware/gallery-loaded.global.ts";
import transitions_45global from "/home/runner/work/cdc-web/cdc-web/src/middleware/transitions.global.ts";
import manifest_45route_45rule from "/home/runner/work/cdc-web/cdc-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  gallery_45loaded_45global,
  transitions_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/cdc-web/cdc-web/src/middleware/auth.ts"),
  "product-exists": () => import("/home/runner/work/cdc-web/cdc-web/src/middleware/product-exists.ts")
}