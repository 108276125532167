import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin({
    name: 'conncetivity',
    parallel: true,
    setup: () => {
        const isOnline = ref(import.meta.server ? true : navigator.onLine);

        if (import.meta.client) {
            addEventListener('offline', () => isOnline.value = false);
            addEventListener('online', () => isOnline.value = true);
        }

        return {
            provide: {
                isOnline
            }
        };
    }
});
