<template>
    <div class="flex w-full flex-col items-center justify-center gap-4">
        <h1 class="text-4xl font-semibold">
            Page not found
        </h1>
        <NuxtLink :to="$route.from ?? { name: 'admin' }">
            <button class="btn btn-primary">
                Go back
            </button>
        </NuxtLink>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    // eslint-disable-next-line vue/component-definition-name-casing
    name: '[...all]',

    setup: () => {
        definePageMeta({ middleware: 'auth' });
    }
});
</script>
