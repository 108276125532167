import { defineNuxtRouteMiddleware, requestIdleCallback } from '#app';
import type { GalleryAttributes } from '~/types';

const useGalleryLoaded = () => useState('galleryLoaded', () => false);

export default defineNuxtRouteMiddleware(async (to) => {
    const galleries = useGalleries();
    const galleryLoaded = useGalleryLoaded();

    if (galleryLoaded.value) return;

    const loadGalleries = async () => {
        await apiFetch<GalleryAttributes[]>('/galleries').then(res => {
            galleryLoaded.value = true;
            galleries.value = res;
            return;
        });
    };

    if (['galleries', 'galleries-slug', 'admin-galleries-id-edit', 'admin-galleries'].includes(to.name)) {
        await loadGalleries();
        return;
    }

    if (import.meta.client) {
        requestIdleCallback(() => void loadGalleries());
    }
});
