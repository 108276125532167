<template>
    <div class="relative mt-[-8.6rem] flex grow flex-col gap-4 sm:mt-0">
        <div class="container absolute top-0 z-10 flex min-w-full justify-between py-4 sm:hidden">
            <NuxtLink :to="{ name: 'products' }"
                      class="btn btn-square btn-sm border-0 bg-white/70 hover:bg-white/90 hover:!text-base-content">
                <Icon name="arrowLeft" size="18" />
            </NuxtLink>
            <ClientOnly>
                <label class="btn btn-square swap btn-sm border-0 bg-white/70 text-red-700 hover:bg-white/90">
                    <input type="checkbox" :checked="isFavourite" @change="toggleFavourite">
                    <Icon name="heart" size="18" class="swap-on" />
                    <Icon name="heartOutline" size="18" class="swap-off" />
                </label>
            </ClientOnly>
        </div>

        <dialog ref="imageModal" class="modal" @submit="slideDuration = originalSlideDuration">
            <form method="dialog"
                  class="modal-box max-w-full px-2 pb-4 pt-3 sm:px-6 sm:pb-6"
                  style="max-width: fit-content">
                <div class="mb-2 flex w-full justify-end">
                    <button class="btn btn-circle btn-ghost btn-xs text-base-content sm:btn-sm">
                        ✕
                    </button>
                </div>
                <NuxtImg v-if="imageToDisplay"
                         :src="imageToDisplay"
                         class="mx-auto rounded" />
            </form>
            <form method="dialog" class="modal-backdrop" @submit="slideDuration = originalSlideDuration">
                <button>close</button>
            </form>
        </dialog>

        <div class="flex flex-col justify-between gap-6 sm:container sm:flex-row lg:gap-4">
            <div class="sm:w-1/2">
                <Splide ref="carousel"
                        :options="{
                            rewind: true,
                            autoplay: true,
                            pauseOnHover: true,
                            slideFocus: true,
                            pagination: false,
                            arrows: false
                        }"
                        class="min-w-40"
                        :aria-label="`Images of ${product.name}`"
                        @splide:move="splide => currentSlide = splide.index">
                    <SplideSlide v-for="(image, index) in product.images" :key="index">
                        <div class="relative overflow-hidden">
                            <NuxtPicture :src="image.source"
                                         provider="weserve"
                                         sizes="100vw sm:300px lg:500px"
                                         class="w-full sm:max-h-[60vh]"
                                         :img-attrs="{ class: 'sm:rounded-xl w-full' }"
                                         @dblclick="() => {
                                             imageToDisplay = image.source;
                                             $refs.imageModal.showModal();
                                             slideDuration = 0
                                         }" />
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1440 320"
                                 class="absolute -bottom-1 fill-base-100 sm:hidden">
                                <!-- eslint-disable-next-line max-len -->
                                <path d="M0,256L60,261.3C120,267,240,277,360,282.7C480,288,600,288,720,277.3C840,267,960,245,1080,245.3C1200,245,1320,267,1380,277.3L1440,288L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z" />
                            </svg>
                        </div>
                    </SplideSlide>
                </Splide>
                <Pagination v-if="product.images.length > 1"
                            :slides-count="product.images.length"
                            :slide-to="carousel?.go"
                            :current-slide="currentSlide" />
            </div>
            <section class="container sm:w-1/2 sm:px-0">
                <h1 class="flex flex-wrap items-center justify-center gap-2 text-center font-bold"
                    :style="{ 'view-transition-name': `${product.slug}-title` }">
                    <ClientOnly>
                        <NuxtLink v-if="isAuthenticated"
                                  :to="{ name: 'admin-products-id-edit', params: { id: product.id } }">
                            <Icon name="edit" />
                        </NuxtLink>
                    </ClientOnly>
                    {{ product.name }}
                    <ClientOnly>
                        <div class="tooltip tooltip-left hidden lg:tooltip-bottom md:block"
                             data-tip="Shortlist as your favourite">
                            <HeartButton class="ml-2"
                                         :model-value="isFavourite"
                                         @update:model-value="toggleFavourite" />
                        </div>
                        <button v-if="shareIsSupported"
                                title="Share"
                                class="btn btn-square btn-ghost btn-sm"
                                @click="shareProduct">
                            <Icon name="share" size="18" />
                        </button>
                    </ClientOnly>
                </h1>
                <p class="mt-8 text-center text-accent">
                    {{ product.shortDescription }}
                </p>
                <div class="relative mt-8 max-h-[40vh] overflow-y-auto md:mt-10">
                    <table class="table table-xs 2xs:table-sm sm:table-md xl:table-lg">
                        <thead class="sticky top-0 bg-base-100 text-sm">
                            <tr>
                                <th>Price</th>
                                <th colspan="2">
                                    Size
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(variation, index) in product.variations"
                                v-once
                                :key="index"
                                tabindex="0"
                                class="hover transition-colors focus:bg-base-200 focus:outline-none">
                                <td class="font-bold">
                                    £{{ variation.cost }}
                                </td>
                                <td :colspan="variation.unitComment ? 1 : 2">
                                    {{ `${variation.unitAmount} ${unitDisplayValue(variation.unit, variation.unitAmount)}` }}
                                </td>
                                <td v-if="variation.unitComment">
                                    <i>({{ variation.unitComment }})</i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
        <div v-if="product.description || product.ingredients.length"
             class="mt-4 w-full grow space-y-8 bg-gradient-to-t from-base-200/70 pb-4">
            <section v-if="product.description" class="container">
                <h2 class="sr-only">
                    Description
                </h2>
                <!-- eslint-disable vue/no-v-html -->
                <div class="prose prose-sm max-w-none dark:prose-invert sm:prose-base lg:prose-lg"
                     v-html="product.description" />
            </section>
            <section v-if="product.ingredients.length" class="container">
                <h2 class="text-xl">
                    Ingredients
                </h2>
                <small class="block text-base-content/60">
                    <i>
                        For allergen advice see ingredients in bold. Product may also contain food colouring.
                    </i>
                </small>
                <p class="mt-4">
                    <span v-for="(ingredient, i) in product.ingredients"
                          :key="i"
                          :class="{ 'font-bold': ingredient.isAllergen }">
                        {{ ingredient.name }}{{ i !== product.ingredients.length - 1 ? ', ' : '' }}
                    </span>
                </p>
            </section>
            <section v-if="product.shelfLife"
                     class="container flex items-center gap-2 font-semibold italic text-base-content/60">
                <h2 class="text-lg">
                    Shelf Life
                </h2>
                <p>
                    - {{ product.shelfLife }} day{{ product.shelfLife > 1 ? 's' : '' }}
                </p>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import useProductStore from '~/stores/products';
import useProductFavourites from '~/composables/useProductFavourites';
import Icon from '~/components/Icon.vue';
import HeartButton from '~/components/public/HeartButton.vue';
import useAuthStore from '~/stores/auth';
import useUnitStore from '~/stores/unit';
import { defineProduct, useSchemaOrg } from '#imports';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import Pagination from '~/components/public/Pagination.vue';

export default defineComponent({
    // eslint-disable-next-line vue/match-component-file-name
    name: 'Product',

    components: {
        Pagination,
        HeartButton,
        Icon,
        Splide,
        SplideSlide
    },

    setup: () => {
        const productStore = useProductStore();
        const unitStore = useUnitStore();
        const authStore = useAuthStore();
        const imageToDisplay = ref<string>();
        const carousel = ref();
        const originalSlideDuration = productStore.product!.images.length === 1 ? 0 : 4000;
        const slideDuration = ref(originalSlideDuration);
        const image = computed(() => productStore.product!.images.at(0)!);
        const title = computed(() => productStore.product!.name + ' | Cina\'s Delicious Cakes');
        const description = computed(() => productStore.product!.shortDescription);
        const currentSlide = ref(0);
        const { isSupported: shareIsSupported, share } = import.meta.client
            ? useShare({
                title: title.value,
                text: 'Check out this product I found on Cina\'s Delicious Cakes',
                url: window.location.href
            })
            : {
                isSupported: computed(() => false),
                share: async () => Promise.resolve()
            } as ReturnType<typeof useShare>;
        const shareProduct = async () => {
            if (!shareIsSupported.value) {
                return;
            }

            await share()
                .then(() => {
                    useTrackEvent('share', {
                        /* eslint-disable @typescript-eslint/naming-convention */
                        content_type: 'website',
                        items: [
                            {
                                item_id: productStore.product!.id,
                                item_name: productStore.product!.name,
                                item_category: productStore.product!.type
                            }
                        ]
                        /* eslint-enable @typescript-eslint/naming-convention */
                    });
                    return;
                })
                .catch(e => {
                    if (e instanceof Error && e.message.includes('Share cancelled')) {
                        return;
                    }
                });
        };

        useSeoMeta({
            title,
            description,
            ogTitle: title,
            ogDescription: description,
            twitterTitle: title,
            twitterDescription: description,

            ogImageHeight: image.value.meta.height,
            ogImageWidth: image.value.meta.width,
            ogImageAlt: productStore.product!.name,
            ogImageUrl: image.value.source,
            ogImageType: 'image/jpeg',
            twitterImage: image.value.source,
            twitterImageAlt: productStore.product!.name
        });

        const ordinalIndicator = (num: number): string => {
            switch (num) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        };
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const unitDisplayValue = unitStore.getDisplayName;

        definePageMeta({ middleware: 'product-exists' });

        const { toggleFavourite, isFavourite } = useProductFavourites(productStore.productId!);
        const url = useRequestURL();

        useSchemaOrg([
            defineProduct({
                name: productStore.product!.name,
                description: productStore.product!.shortDescription,
                image: productStore.product!.images.at(0)!.source,
                // todo add brand
                offers: productStore.product!.variations.map(variation => ({
                    price: variation.cost!,
                    priceCurrency: 'GBP',
                    itemCondition: 'https://schema.org/NewCondition',
                    availability: 'https://schema.org/InStock',
                    url: url.href
                })),
                aggregateOffer: {
                    lowPrice: productStore.product!.variations.at(0)!.cost!,
                    highPrice: productStore.product!.variations.at(productStore.product!.variations.length - 1)!.cost!,
                    offerCount: productStore.product!.variations.length
                }
            })
        ]);

        if (import.meta.client) {
            addEventListener('blur', () => slideDuration.value = 0);
            addEventListener('focus', () => slideDuration.value = originalSlideDuration);

            onUnmounted(() => {
                productStore.productId = null;
                addEventListener('blur', () => slideDuration.value = 0);
                addEventListener('focus', () => slideDuration.value = originalSlideDuration);
            });
        }

        return {
            isAuthenticated: authStore.isAuthenticated,
            product: productStore.product!,
            toggleFavourite,
            isFavourite,
            imageToDisplay,
            ordinalIndicator,
            unitDisplayValue,
            carousel,
            slideDuration,
            originalSlideDuration,
            shareIsSupported,
            shareProduct,
            currentSlide
        };
    }
});
</script>

<style lang="scss">
// so carousel items start from the top of the page
.carousel__track {
    align-items: flex-start !important;
}
</style>
