<template>
    <div class="bg-gradient-to-t from-base-200/70 to-base-100">
        <div class="container my-6">
            <section class="mb-8 text-center">
                <h1 class="mb-2">
                    {{ gallery.name }}
                </h1>
                <p v-if="gallery.description" class=" text-justify text-sm text-base-content/60">
                    {{ gallery.description }}
                </p>
            </section>

            <div role="grid"
                 class="flex flex-row flex-wrap items-start justify-evenly gap-2">
                <figure v-for="image in gallery.images"
                        v-once
                        :key="image.source"
                        role="tree"
                        aria-haspopup="true"
                        class="group relative max-h-80 max-w-80 cursor-pointer overflow-hidden rounded-lg shadow-lg
                               2xl:max-h-96 2xl:max-w-96"
                        @click="imageToDisplay = image; $refs.imageModal.showModal()">
                    <NuxtPicture :src="image.source"
                                 loading="lazy"
                                 provider="weserve"
                                 sizes="300px sm:700px"
                                 :img-attrs="{ class: 'transition group-hover:scale-110' }"
                                 class="object-cover object-center" />
                    <p class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded bg-primary/80 px-2
                              py-1 text-center text-sm text-primary-content/80 opacity-0 transition
                              group-hover:opacity-100">
                        Click to enlarge
                    </p>
                </figure>
            </div>

            <dialog ref="imageModal" class="modal">
                <form method="dialog"
                      class="modal-box max-w-fit px-2 pb-4 pt-3 sm:px-6 sm:pb-6"
                      @submit="imageToDisplay = undefined">
                    <div class="mb-2 flex w-full justify-end">
                        <button class="btn btn-circle btn-ghost btn-xs text-base-content sm:btn-sm">
                            ✕
                        </button>
                    </div>
                    <NuxtImg v-if="imageToDisplay"
                             :width="imageToDisplay.meta.width"
                             :height="imageToDisplay.meta.height"
                             :src="imageToDisplay.source"
                             class="mx-auto max-h-full max-w-full rounded" />
                </form>
                <form method="dialog" class="modal-backdrop" @submit="imageToDisplay = undefined">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from '#app';
import useGalleries from '~/composables/useGalleries';
import { helpers } from '@typed-router';
import type { ImageAttributes } from '~/types';

export default defineComponent({
    // eslint-disable-next-line vue/match-component-file-name
    name: 'Gallery',

    setup: () => {
        const galleries = useGalleries();
        const router = useRouter();
        const slug = router.currentRoute.value.params.slug;
        const gallery = computed(() => galleries.value.find(gallery => gallery.slug === slug));
        const imageToDisplay = ref<ImageAttributes>();
        const title = gallery.value!.name + ' | Galleries | Cina\'s Delicious Cakes';
        const description = gallery.value!.description ?? 'Gallery of products made by Cina\'s Delicious Cakes.';

        // todo - implement lazy loading of images
        definePageMeta({
            middleware: (to) => {
                const galleries = useGalleries();

                if (!galleries.value.find(gallery => gallery.slug === to.params.slug)) {
                    return helpers.route({ name: 'galleries' });
                }
            }
        });

        useSeoMeta({
            title,
            description,
            ogTitle: title,
            ogDescription: description,
            twitterTitle: title,
            twitterDescription: description
        });

        return {
            gallery,
            imageToDisplay
        };
    }
});
</script>
