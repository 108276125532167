<template>
    <div class="container mb-6 flex flex-wrap items-center justify-evenly gap-6">
        <NuxtLink v-for="gallery in galleriesWithImages"
                  :key="gallery.id"
                  class="overflow-hidden rounded bg-white shadow"
                  :to="{ name: 'galleries-slug', params: { slug: gallery.slug } }">
            <NuxtImg provider="weserve"
                     :src="gallery.images[0].source"
                     width="300"
                     loading="lazy" />
            <p class="bg-base-200/30 px-2 py-4 text-center text-lg">
                {{ gallery.name }}
            </p>
        </NuxtLink>
        <p v-if="!galleriesWithImages.length" class="mt-24 select-none text-lg text-base-content/30">
            There are no galleries at the moment, please check back later.
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useGalleries from '~/composables/useGalleries';
import { helpers } from '@typed-router';

export default defineComponent({
    // eslint-disable-next-line vue/match-component-file-name
    name: 'Galleries',

    setup: () => {
        const galleries = useGalleries();
        const title = 'Galleries | Cina\'s Delicious Cakes';
        const description = 'Galleries of cakes made by Cina\'s Delicious Cakes.';
        const galleriesWithImages = computed(() => galleries.value.filter(gallery => gallery.images.length > 0));

        definePageMeta({
            middleware: () => {
                const galleries = useGalleries();

                if (galleries.value.length > 1) {
                    return;
                }

                if (galleries.value.length === 0) {
                    return helpers.route({ name: 'index' });
                }
            }
        });

        useSeoMeta({
            title,
            description,
            ogTitle: title,
            ogDescription: description,
            twitterTitle: title,
            twitterDescription: description
        });

        return {
            galleriesWithImages
        };
    }
});
</script>
