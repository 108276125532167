<template>
    <div>
        <div class="card bg-base-100">
            <div class="card-body">
                <div class="flex w-full flex-wrap items-center justify-between">
                    <NuxtLink :to="{ name: 'admin-products-id-edit', params: { id: productId } }">
                        <button class="btn btn-outline">
                            <Icon name="arrowLeft" />
                            Back
                        </button>
                    </NuxtLink>
                    <button class="btn btn-success" :disabled="!hasChanges" @click="save">
                        Save
                    </button>
                    <button class="btn btn-primary" @click="addVariation">
                        Add variation
                    </button>
                </div>

                <div class="form-control w-full items-start">
                    <label class="label" for="ingredients">
                        Ingredients
                    </label>
                    <AdminMultiSelect id="ingredients"
                                      v-model="selectedIngredients"
                                      :options="ingredientStore.ingredients" />
                </div>
            </div>
        </div>

        <div class="mt-2 grid grid-cols-1 justify-center gap-6 lg:grid-cols-2 2xl:grid-cols-3">
            <ProductVariation v-for="(variation, index) in variations"
                              :key="index"
                              :ingredients="selectedIngredients"
                              :variation-id="variation.id" />
        </div>

        <div v-if="!variations.length" class="mt-12 flex justify-center">
            <h1 class="text-base-content/50">
                No variations has been added.
            </h1>
        </div>

        <dialog ref="hasChangesModal" class="modal">
            <form method="dialog" class="modal-box text-base-content">
                <h3 class="text-lg font-bold">
                    Continue?
                </h3>
                <p class="py-6">
                    You have unsaved changes. Are you sure you want to leave?
                </p>
                <div class="flex items-center justify-end gap-2">
                    <button class="btn btn-ghost" type="submit" @click="confirmedLeave = false">
                        Cancel
                    </button>
                    <button class="btn btn-error" type="submit" @click="confirmedLeave = true">
                        Confirm
                    </button>
                </div>
            </form>
        </dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import ProductVariation from '~/components/admin/products/ProductVariation.vue';
import useProductStore from '~/stores/products';
import parseError from '~/utils/parseError';
import { useRouter } from '#app';
import type { IngredientAttributes, ProductAttributes, ProductVariationAttributes } from '~/types';
import { useToast } from 'vue-toastify';
import { useLoader } from '~/composables';
import apiFetch from '~/utils/apiFetch';
import useVariations from '~/composables/useVariations';
import useIngredientStore from '~/stores/ingredient';

export default defineComponent({
    name: 'Variations',

    components: { ProductVariation },

    setup: async () => {
        const productStore = useProductStore();
        const ingredientStore = useIngredientStore();
        const router = useRouter();
        const toast = useToast();
        const loader = useLoader();
        const selectedIngredients = ref<IngredientAttributes[]>([]);
        const routeId = Number(router.currentRoute.value.params.id);
        const hasChangesModal = ref<HTMLDialogElement>();
        const confirmedLeave = ref<boolean>();
        const variations = useVariations();
        const loading = ref(false);
        let initialVariations = JSON.stringify(variations.value);
        const hasChanges = computed(() => JSON.stringify(variations.value) !== initialVariations);

        onBeforeRouteLeave(async () => {
            if (hasChanges.value) {
                hasChangesModal.value!.showModal();
                return await new Promise(resolve => {
                    const interval = setInterval(() => {
                        if (confirmedLeave.value !== undefined) {
                            clearInterval(interval);
                            selectedIngredients.value = [];
                            resolve(confirmedLeave.value);
                        }
                    }, 50);
                });
            }
        });

        productStore.productId = routeId;

        definePageMeta({ middleware: ['product-exists', 'auth'] });
        await productStore.ensureFullyLoaded(routeId).catch(async reason => {
            const handler = parseError(reason);
            if (handler.isNotFoundError) {
                return router.replace({ name: 'admin-products' });
            }

            throw reason;
        });

        variations.value = productStore.product?.variations.length
            ? productStore.product.variations
            : [
                {
                    // negative number between -1 and -100 for uniqueness
                    id: Math.floor(Math.random() * -100),
                    isDefault: true,
                    cost: null,
                    unitId: NaN,
                    markupPercentage: 0,
                    unitAmount: NaN,
                    unitComment: null,
                    preparationTime: 0,
                    unit: {
                        name: null,
                        id: NaN,
                        abbreviation: '',
                        isRelative: false
                    },
                    // todo a variation unit and amount can be the same but have different price based on flavour
                    //  (walnut costs more than poppy seed)

                    // todo a one packaging maybe used multiple times for a variation
                    // (eg.: muffin box of 6 will have 6 muffin cups)
                    packagings: [],
                    ingredientsAmounts: []
                } as ProductVariationAttributes
            ];
        initialVariations = JSON.stringify(variations.value);
        selectedIngredients.value = productStore.product?.ingredients ?? [];

        // eslint-disable-next-line vue/no-watch-after-await
        watch(() => selectedIngredients.value, (newIngredients, oldIngredients) => {
            variations.value = variations.value.map(variation => {
                const newVariation = { ...variation };

                newVariation.ingredientsAmounts = newVariation.ingredientsAmounts!.filter(ia => {
                    return !!newIngredients.find(i => i.id === ia.ingredientId);
                });

                return newVariation;
            });

            const removedIngredients = oldIngredients
                .filter(ingredient => !newIngredients.find(i => i.id === ingredient.id));

            if (removedIngredients.length) {
                // if there are removed ingredients, we need to update productsCount on the ingredient
                ingredientStore.ingredients = ingredientStore.ingredients
                    .map(ingredient => {
                        const newIngredient = { ...ingredient };
                        const removedIngredient = removedIngredients.find(i => i.id === ingredient.id);

                        if (removedIngredient) {
                            newIngredient.productsCount = Math.max(0, newIngredient.productsCount! - 1);
                        }

                        return newIngredient;
                    });
            }
        });

        const addVariation = () => {
            variations.value.push({
                // match the previous one
                ...variations.value[variations.value.length - 1],
                id: Math.floor(Math.random() * -100),
                isDefault: variations.value.length === 0,
                cost: null,
                unitAmount: NaN,
                packagings: [],
                unitId: NaN,
                markupPercentage: 0
            });
        };
        const save = async () => {
            if (!hasChanges.value) return;

            loader?.on();
            loading.value = true;

            const body = {
                variations: variations.value.map(v => {
                    // unit relation isn't needed; id isn't needed as variations get recreated
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { unit, id, packagings, ...rest } = v;

                    rest.packagingIds = packagings.map(p => p.id);

                    return rest;
                })
            };

            if (body.variations.length === 1) {
                // not necessary just defensive programming
                body.variations[0].isDefault = true;
            }

            return await apiFetch<ProductAttributes>(`products/${productStore.productId!}`, { method: 'PATCH', body })
                .then(product => {
                    productStore.products = productStore.products
                        .map(p => p.id === product.id ? product : p);
                    variations.value = product.variations;
                    initialVariations = JSON.stringify(product.variations);
                    toast.success(`Variation${product.variations.length > 1 ? 's' : ''} saved!`);

                    return product;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage))
                .finally(() => {
                    loader?.off();
                    loading.value = false;
                });
        };

        return {
            variations,
            addVariation,
            hasChanges,
            save,
            productId: productStore.productId,
            hasChangesModal,
            confirmedLeave,
            selectedIngredients,
            ingredientStore
        };
    }
});
</script>
