import { defineNuxtPlugin } from '#app';
import { captureException } from '@sentry/vue';

export default defineNuxtPlugin({
    name: 'error',
    parallel: true,
    setup: nuxt => {
        nuxt.hook('app:chunkError', e => {
            captureException(new Error('app:chunkError'), {
                extra: { ...e }
            });
        });
    }
});
