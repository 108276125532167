import { defineNuxtRouteMiddleware } from '#app';

export default defineNuxtRouteMiddleware((to, from) => {
    // when visiting a non-existent page, the name will be undefined
    if ((to.name as string | undefined)?.startsWith('admin')) {
        const fromLevel = from.path.split('/').length;
        const toLevel = to.path.split('/').length;

        if (!to.meta.pageTransition) {
            to.meta.pageTransition = { mode: 'out-in' };
        }
        if (!from.meta.pageTransition) {
            from.meta.pageTransition = { mode: 'out-in' };
        }

        if (fromLevel !== toLevel) {
            // @ts-expect-error
            from.meta.pageTransition.name = toLevel > fromLevel ? 'slide-left' : 'slide-right';
            // @ts-expect-error
            to.meta.pageTransition.name = toLevel > fromLevel ? 'slide-left' : 'slide-right';
        } else {
            // @ts-expect-error
            from.meta.pageTransition.name = to.meta.pageTransition.name = 'fade';
        }
    } else {
        if (import.meta.server) return;
        if (!document.startViewTransition) return;

        // Disable built-in Vue transitions
        to.meta.pageTransition = false;
        to.meta.layoutTransition = false;
    }
});
