import type { IngredientAttributes, ProductVariationAttributes } from '~/types';

export default function variationProjectedCost(
    variation: ProductVariationAttributes,
    hourlyRate: number,
    utilityCosts: number,
    ingredients: IngredientAttributes[]
): number {
    // raw ingredient costs
    let cost = variation.ingredientsAmounts!.reduce((prev, next) => {
        const ingredient = ingredients.find(i => i.id === next.ingredientId)!;

        return prev + next.amount * (ingredient.cost! / ingredient.unitAmount!);
    }, 0);

    if (variation.preparationTime) {
        // time factors
        cost += variation.preparationTime * (hourlyRate / 60) + variation.preparationTime * (utilityCosts / 60);
    }

    // packaging costs
    cost += variation.packagings.reduce((prev, next) => prev + next.cost / next.unitAmount, 0);

    if (variation.markupPercentage) {
        // extra markup
        cost += cost / 100 * variation.markupPercentage;
    }

    // VAT
    cost *= 1.2;

    // round to two decimal places
    return Math.round(cost * 100) / 100;
}
