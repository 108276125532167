<template>
    <div class="flex flex-col justify-center">
        <div class="card bg-base-100 shadow-xl">
            <div role="form" class="card-body justify-between space-y-6">
                <div class="space-y-2">
                    <div class="flex flex-col justify-between gap-2 lg:flex-row">
                        <div class="form-control items-start lg:w-1/2">
                            <label class="label justify-start" for="product-name">
                                Name <span class="text-red-600">*</span>
                            </label>
                            <input id="product-name"
                                   v-model="draftProduct.name"
                                   type="text"
                                   autofocus
                                   placeholder="The name of the product"
                                   class="input input-bordered w-full bg-base-200/30">
                        </div>
                        <div class="form-control items-start lg:w-1/2">
                            <label class="label inline-flex" for="product-slug">
                                Url slug
                            </label>
                            <input id="product-slug"
                                   v-model="draftProduct.slug"
                                   type="text"
                                   autofocus
                                   placeholder="Custom url to display"
                                   class="input input-bordered w-full bg-base-200/30">
                        </div>
                    </div>
                    <div class="flex flex-col justify-between gap-2 sm:flex-row">
                        <div class="form-control w-full items-start md:w-1/2">
                            <label class="label" for="type">
                                Type <span class="text-red-600">*</span>
                            </label>
                            <select id="type"
                                    v-model="draftProduct.type"
                                    class="select select-bordered w-full bg-base-200/30"
                                    placeholder="The category the product belongs to.">
                                <option v-for="type in productTypes" :key="type" v-text="type" />
                            </select>
                        </div>
                        <div class="form-control w-full items-start md:w-1/2">
                            <label class="label inline-flex gap-1" for="product-slug">
                                Shelf Life <small>(in days)</small>
                            </label>
                            <input id="product-slug"
                                   v-model="draftProduct.shelfLife"
                                   type="number"
                                   min="1"
                                   max="65535"
                                   placeholder="Days before the product expires"
                                   class="input input-bordered w-full bg-base-200/30">
                        </div>
                    </div>
                    <div class="form-control items-start">
                        <label class="label" for="shortDescription">
                            Short Description <span class="text-red-600">*</span>
                        </label>
                        <textarea id="shortDescription"
                                  v-model="draftProduct.shortDescription"
                                  class="textarea textarea-bordered h-24 max-h-48 w-full bg-base-200/30"
                                  placeholder="The short description of the product..." />
                    </div>
                </div>
                <div class="flex justify-center md:justify-end">
                    <button class="btn btn-primary px-12"
                            :disabled="!canSave"
                            @click="save">
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import type { ProductAttributes, MaybeNulls } from '~/types';
import useProductStore from '~/stores/products';
import parseError from '~/utils/parseError';
import { useToast } from 'vue-toastify';
import apiFetch from '~/utils/apiFetch';
import useProductTypes from '~/composables/useProductTypes';

export default defineComponent({
    name: 'Create',

    setup: () => {
        definePageMeta({ middleware: 'auth' });
        const productStore = useProductStore();
        const toast = useToast();
        const draftProduct = ref<MaybeNulls<ProductAttributes>>({
            id: null,
            name: null,
            slug: null,
            description: null,
            shortDescription: null,
            instructions: null,
            type: null,
            images: [],
            variations: [],
            ingredients: [],
            createdAt: null
        });
        const canSave = computed(() => {
            return draftProduct.value.name &&
                draftProduct.value.shortDescription &&
                draftProduct.value.type;
        });

        const save = async () => {
            const nonNullObject: Record<string, any> = {};

            Object.keys(draftProduct.value).forEach(key => {
                const value: any = draftProduct.value[key];

                if (value !== null) {
                    nonNullObject[key] = value;
                }
            });

            return apiFetch<ProductAttributes>('products', {
                method: 'POST',
                body: nonNullObject
            })
                .then(async product => {
                    productStore.$patch(state => {
                        state.draftProduct = product;
                        state.products.push(product);
                    });

                    return navigateTo({ name: 'admin-products-id-edit', params: { id: product.id } });
                })
                .catch(reason => {
                    const handler = parseError(reason);
                    toast.error(handler.errorMessage ?? 'Something has gone wrong.');
                });
        };

        return {
            save,
            productStore,
            productTypes: useProductTypes(),
            canSave,
            draftProduct
        };
    }
});
</script>
