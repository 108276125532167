<template>
    <div>
        <div class="card bg-base-100 shadow-xl">
            <div class="card-body">
                <div class="card-title justify-between">
                    <h1>Products</h1>
                    <NuxtLink :to="{ name: 'admin-products-create' }" :external="false">
                        <button class="btn btn-outline btn-primary">
                            Add Product
                        </button>
                    </NuxtLink>
                </div>
                <div class="my-4 flex flex-wrap items-center justify-around gap-x-6 gap-y-2 md:justify-between">
                    <input v-if="productStore.products.length > 1"
                           v-model="search"
                           type="text"
                           placeholder="Search..."
                           class="input input-bordered grow">
                    <label class="label cursor-pointer gap-4">
                        <span class="label-text font-bold">
                            {{ status === 'draft' ? 'Draft' : status === 'published' ? 'Published' : 'All' }} products
                        </span>
                        <input type="checkbox"
                               :checked="status !== undefined"
                               class="checkbox checkbox-lg"
                               :indeterminate="status === 'draft'"
                               @change="status = status === 'draft'
                                   ? undefined
                                   : status === 'published' ? 'draft' : 'published'
                               ">
                    </label>
                </div>
                <div class="overflow-x-auto" style="max-height: 65vh">
                    <table class="table">
                        <thead class="sticky top-0 bg-base-100 shadow">
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in products" :key="product.id" class="hover">
                                <td>
                                    <NuxtLink :to="{ name: 'admin-products-id', params: { id: product.id }}"
                                              class="link">
                                        {{ product.name }}
                                    </NuxtLink>
                                </td>
                                <td>{{ product.type }}</td>
                                <td>
                                    <div class="flex">
                                        <NuxtLink :title="`Edit ${product.name}`"
                                                  :to="{ name: 'admin-products-id-edit', params: { id: product.id }}"
                                                  class="mx-auto">
                                            <button class="btn btn-square btn-outline btn-info btn-sm">
                                                <Icon name="edit" />
                                            </button>
                                        </NuxtLink>
                                        <button class="btn btn-square btn-outline btn-error btn-sm"
                                                :title="`Delete ${product.name}`"
                                                @click="productToDelete = product; $refs.deleteConfirm.showModal()">
                                            <Icon name="delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!products.length">
                                <td colspan="3">
                                    <div class="mx-auto flex flex-col items-center justify-center gap-2">
                                        <p class="select-none text-lg font-bold text-base-content/40">
                                            No Products found.
                                        </p>
                                        <br>
                                        <NuxtLink :to="{ path: 'products/create' }">
                                            <button class="btn btn-info">
                                                Add Product
                                            </button>
                                        </NuxtLink>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <dialog ref="deleteConfirm" class="modal">
            <form method="dialog" class="modal-box text-base-content">
                <h3 class="text-lg font-bold">
                    Confirm delete
                </h3>
                <p class="py-6">
                    Are you sure you want to delete
                    <span v-if="productToDelete" class="font-bold">{{ productToDelete.name }}</span>?
                </p>
                <div class="flex items-center justify-end gap-2">
                    <button class="btn btn-ghost" type="submit">
                        Cancel
                    </button>
                    <button class="btn btn-error" type="submit" @click="deleteProduct">
                        Confirm
                    </button>
                </div>
            </form>
        </dialog>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import useProductStore from '~~/src/stores/products';
import Icon from '~/components/Icon.vue';
import type { ProductAttributes, IngredientAttributes } from '~/types';
import apiFetch from '~/utils/apiFetch';
import useIngredientStore from '~/stores/ingredient';

export default defineComponent({
    name: 'Index',

    components: { Icon },

    setup: () => {
        definePageMeta({ middleware: 'auth' });
        const productStore = useProductStore();
        const ingredientStore = useIngredientStore();
        const search = ref('');
        const status = ref<'published' | 'draft' | undefined>(undefined);
        const products = computed(() => {
            const searchTerm = search.value.toLowerCase();

            return productStore.products.filter(p => {
                const isMatch = p.name.toLowerCase().includes(searchTerm) || p.type.toLowerCase().includes(searchTerm);

                if (status.value) {
                    return isMatch && p.inDraft === (status.value === 'draft');
                }

                return isMatch;
            });
        });
        const productToDelete = ref<ProductAttributes>();
        const deleteProduct = async () => {
            if (!productToDelete.value) return;

            return apiFetch(`products/${productToDelete.value.id}`, { method: 'DELETE' })
                .then(() => {
                    productStore.products = productStore.products.filter(
                        product => product.id !== productToDelete.value!.id
                    );
                    productToDelete.value = undefined;
                    // fetch ingredients to update the products_count
                    void apiFetch<IngredientAttributes[]>('/ingredients?with[0]=products_count')
                        // eslint-disable-next-line promise/no-nesting
                        .then(ingredients => ingredientStore.ingredients = ingredients);
                    return;
                });
        };

        return {
            productStore,
            products,
            deleteProduct,
            search,
            productToDelete,
            status
        };
    }
});
</script>

<style lang="scss" scoped>
.link {
    @apply underline text-blue-700;
    &:visited {
        @apply text-primary/75;
    }
}
</style>
