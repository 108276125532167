import { useState } from '#app';
import type { GalleryAttributes } from '~/types';

/**
 * Product types that are available.
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useGalleries = () => useState<GalleryAttributes[]>('galleries', () => []);

export default useGalleries;
