<template>
    <div class="min-h-screen bg-base-200">
        <NuxtLayout :name="$route.fullPath?.includes('admin') ? 'admin' : 'default'">
            <div class="flex grow flex-col items-center justify-center gap-8">
                <h1 class="select-none text-center text-4xl font-semibold text-base-content/60">
                    {{ error.message ?? 'An error has occurred 😤' }}
                </h1>
                <NuxtLink :to="$route.from ?? { name: 'index' }" @click="clearError">
                    <button class="btn btn-primary">
                        Go back
                    </button>
                </NuxtLink>
            </div>
        </NuxtLayout>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { NuxtError } from '#app';

export default defineComponent({
    name: 'Error',

    props: {
        error: {
            type: Object as PropType<NuxtError>,
            required: true
        }
    },

    setup: (props) => {
        if (import.meta.dev)  {
            // eslint-disable-next-line no-console
            console.error(props.error);
        }
    }
});
</script>
