import { defineStore } from 'pinia';
import type { UserAttributes } from '~/types';
import apiFetch from '~/utils/apiFetch';
import parseError from '~/utils/parseError';

interface State {
    user: null | UserAttributes;
}

const useAuthStore = defineStore('auth', {
    state: (): State => {
        return {
            user: null
        };
    },

    getters: {
        isAuthenticated: (state): boolean => {
            return state.user !== null;
        }
    },

    actions: {
        async logout(): Promise<void> {
            const resetLocal = () => {
                this.user = null;
                document.cookie = '';
            };

            return apiFetch('logout', { method: 'POST' })
                .then(resetLocal)
                .catch(errorResponse => {
                    const handler = parseError(errorResponse);

                    // we're already logged out
                    if (handler.isUnauthenticatedError) {
                        resetLocal();
                        return;
                    }

                    throw errorResponse;
                });
        }
    }
});

export default useAuthStore;
