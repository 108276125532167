import { defineStore } from 'pinia';
import type { IngredientAttributes } from '~/types';

interface State {
    /**
     * Globally available ingredients.
     */
    ingredients: IngredientAttributes[];
}

const useIngredientStore = defineStore('ingredient', {
    state: (): State => {
        return {
            ingredients: []
        };
    }
});

export default useIngredientStore;
