import { default as _91_46_46_46all_93u3ScyEhxNhMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/[...all].vue?macro=true";
import { default as _91slug_93_45cakesTeXu14EyqQMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/[slug]-cakes.vue?macro=true";
import { default as abouteMHOUAklXPMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/about.vue?macro=true";
import { default as _91_46_46_46all_93EMcC1IsCMMMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/[...all].vue?macro=true";
import { default as editzlPCAbgDxqMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/galleries/[id]/edit.vue?macro=true";
import { default as indexMolPYvuaYWMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/galleries/index.vue?macro=true";
import { default as index6fipX1scU5Meta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/index.vue?macro=true";
import { default as indexGpV9GAts7iMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/ingredients/index.vue?macro=true";
import { default as indexkXsC8H844ZMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/packagings/index.vue?macro=true";
import { default as indexXzoieCQ8IlMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/[id]/edit/index.vue?macro=true";
import { default as variationsihiN7fmkJbMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/[id]/edit/variations.vue?macro=true";
import { default as indexCjkeMO5XxhMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/[id]/index.vue?macro=true";
import { default as create6ktwu6QGjZMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/create.vue?macro=true";
import { default as indexbij1IaBD5aMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/index.vue?macro=true";
import { default as settingsH5v3ew5tDGMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/settings.vue?macro=true";
import { default as indexfKt1shXRcCMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/admin/units/index.vue?macro=true";
import { default as contactGTtWCCKIzKMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/contact.vue?macro=true";
import { default as cookie_45policyk4xZkIgI0WMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/cookie-policy.vue?macro=true";
import { default as faqsCj9bTJQRR6Meta } from "/home/runner/work/cdc-web/cdc-web/src/pages/faqs.vue?macro=true";
import { default as _91slug_93AzhfGk7enXMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/galleries/[slug].vue?macro=true";
import { default as indexucHl4J5EWPMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/galleries/index.vue?macro=true";
import { default as index7nAasZWNpKMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/index.vue?macro=true";
import { default as loginY911UmO9uNMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/login.vue?macro=true";
import { default as _91slug_93UpmQVJYbh0Meta } from "/home/runner/work/cdc-web/cdc-web/src/pages/products/[slug].vue?macro=true";
import { default as indexThYpOgA3VgMeta } from "/home/runner/work/cdc-web/cdc-web/src/pages/products/index.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/[...all].vue")
  },
  {
    name: "slug-cakes",
    path: "/:slug()-cakes",
    meta: _91slug_93_45cakesTeXu14EyqQMeta || {},
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/[slug]-cakes.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/about.vue")
  },
  {
    name: "admin-all",
    path: "/admin/:all(.*)*",
    meta: { ...(_91_46_46_46all_93EMcC1IsCMMMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/[...all].vue")
  },
  {
    name: "admin-galleries-id-edit",
    path: "/admin/galleries/:id()/edit",
    meta: { ...(editzlPCAbgDxqMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/galleries/[id]/edit.vue")
  },
  {
    name: "admin-galleries",
    path: "/admin/galleries",
    meta: { ...(indexMolPYvuaYWMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/galleries/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: { ...(index6fipX1scU5Meta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/index.vue")
  },
  {
    name: "admin-ingredients",
    path: "/admin/ingredients",
    meta: { ...(indexGpV9GAts7iMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/ingredients/index.vue")
  },
  {
    name: "admin-packagings",
    path: "/admin/packagings",
    meta: { ...(indexkXsC8H844ZMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/packagings/index.vue")
  },
  {
    name: "admin-products-id-edit",
    path: "/admin/products/:id()/edit",
    meta: { ...(indexXzoieCQ8IlMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/[id]/edit/index.vue")
  },
  {
    name: "admin-products-id-edit-variations",
    path: "/admin/products/:id()/edit/variations",
    meta: { ...(variationsihiN7fmkJbMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/[id]/edit/variations.vue")
  },
  {
    name: "admin-products-id",
    path: "/admin/products/:id()",
    meta: { ...(indexCjkeMO5XxhMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/[id]/index.vue")
  },
  {
    name: "admin-products-create",
    path: "/admin/products/create",
    meta: { ...(create6ktwu6QGjZMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/create.vue")
  },
  {
    name: "admin-products",
    path: "/admin/products",
    meta: { ...(indexbij1IaBD5aMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/products/index.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: { ...(settingsH5v3ew5tDGMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/settings.vue")
  },
  {
    name: "admin-units",
    path: "/admin/units",
    meta: { ...(indexfKt1shXRcCMeta || {}), ...{"layout":"admin"} },
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/admin/units/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/contact.vue")
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/cookie-policy.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/faqs.vue")
  },
  {
    name: "galleries-slug",
    path: "/galleries/:slug()",
    meta: _91slug_93AzhfGk7enXMeta || {},
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/galleries/[slug].vue")
  },
  {
    name: "galleries",
    path: "/galleries",
    meta: indexucHl4J5EWPMeta || {},
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/galleries/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/login.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    meta: _91slug_93UpmQVJYbh0Meta || {},
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/products/[slug].vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/home/runner/work/cdc-web/cdc-web/src/pages/products/index.vue")
  }
]