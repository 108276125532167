<template>
    <div>
        <div class="card bg-base-100 shadow">
            <div class="card-body">
                <div class="card-title justify-between">
                    <h1>Galleries</h1>
                    <button class="btn btn-outline btn-primary"
                            @click="() => {createGallery();$refs.draftDialog.showModal()}">
                        Add Gallery
                    </button>
                </div>
                <div class="overflow-x-auto">
                    <table v-if="galleries.length" class="table">
                        <thead>
                            <tr>
                                <th />
                                <th>Name</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(gallery, i) in galleries" :key="gallery.id">
                                <td>{{ i + 1 }}</td>
                                <td>{{ gallery.name }}</td>
                                <td>
                                    <div class="flex justify-end gap-2">
                                        <NuxtLink :to="{ name: 'admin-galleries-id-edit', params: { id: gallery.id } }">
                                            <button class="btn btn-square btn-outline btn-secondary btn-sm">
                                                <Icon name="gallery" />
                                            </button>
                                        </NuxtLink>
                                        <NuxtLink v-if="gallery.images.length"
                                                  :to="{ name: 'galleries-slug', params: { slug: gallery.slug } }">
                                            <button class="btn btn-square btn-outline btn-accent btn-sm">
                                                <Icon name="eye" />
                                            </button>
                                        </NuxtLink>
                                        <button class="btn btn-square btn-outline btn-info btn-sm"
                                                @click="() => {
                                                    draftGallery = {...gallery};
                                                    $refs.draftDialog.showModal()
                                                }">
                                            <Icon name="edit" />
                                        </button>
                                        <button class="btn btn-square btn-outline btn-error btn-sm"
                                                @click="deleteGallery(gallery)">
                                            <Icon name="delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="flex w-full flex-col items-center justify-center gap-8 px-2 py-4">
                        <p class="text-lg text-base-content/30">
                            No Galleries yet.
                        </p>
                        <button class="btn btn-primary mx-auto"
                                @click="() => {createGallery();$refs.draftDialog.showModal()}">
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <dialog ref="draftDialog" class="modal">
            <form v-if="draftGallery" method="dialog" class="modal-box space-y-2 text-base-content">
                <h3 class="text-lg font-bold">
                    {{ isNaN(draftGallery.id) ? 'Create' : 'Update' }} Gallery
                </h3>

                <div class="form-control items-start">
                    <label class="label justify-start" for="name">
                        Name <span class="text-red-600">*</span>
                    </label>
                    <input id="name"
                           v-model="draftGallery.name"
                           type="text"
                           autofocus
                           placeholder="The name of the gallery"
                           class="input input-bordered w-full bg-base-200/30">
                </div>

                <div class="form-control items-start">
                    <label class="label justify-start" for="slug">
                        Slug
                    </label>
                    <input id="slug"
                           v-model="draftGallery.slug"
                           type="text"
                           placeholder="Custom url to display"
                           class="input input-bordered w-full bg-base-200/30">
                </div>

                <div class="form-control items-start">
                    <label class="label justify-start" for="description">
                        Description
                    </label>
                    <textarea id="description"
                              v-model="draftGallery.description"
                              type="text"
                              placeholder="The description of the gallery"
                              class="textarea textarea-bordered w-full bg-base-200/30" />
                </div>

                <div class="flex items-center justify-end gap-2">
                    <button class="btn btn-ghost"
                            type="submit"
                            @click="() =>{$refs.draftDialog.close();draftGallery = null}">
                        Cancel
                    </button>
                    <button class="btn"
                            :class="[isNaN(draftGallery.id) ? 'btn-success' : 'btn-info']"
                            type="submit"
                            @click="() => {
                                $refs.draftDialog.close();
                                isNaN(draftGallery.id)
                                    ? storeGallery(draftGallery)
                                    : updateGallery(draftGallery)
                            }">
                        Save
                    </button>
                </div>
            </form>
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { GalleryAttributes } from '~/types';
import { useToast } from 'vue-toastify';
import apiFetch from '~/utils/apiFetch';
import useGalleries from '~/composables/useGalleries';
import { useRouter } from '#app';

export default defineComponent({
    name: 'Index',

    setup: async () => {
        definePageMeta({ middleware: 'auth' });
        const galleries = useGalleries();
        const router = useRouter();

        if (galleries.value.length === 0) {
            galleries.value = await apiFetch<GalleryAttributes[]>('galleries');
        }

        const draftDialog = ref<HTMLDialogElement>();
        const draftGallery = ref<GalleryAttributes | null>(null);
        const toast = useToast();

        const createGallery = () => {
            draftGallery.value = {
                id: NaN,
                description: '',
                slug: '',
                name: '',
                images: []
            };
        };
        const storeGallery = async (gallery: GalleryAttributes) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...body } = gallery;

            await apiFetch<GalleryAttributes>('galleries', { method: 'POST', body })
                .then(async galleryResource => {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (!galleryResource.images) {
                        galleryResource.images = [];
                    }

                    galleries.value.push(galleryResource);
                    toast.success('Gallery created!');
                    draftGallery.value = null;
                    await router.push({ name: 'admin-galleries-id-edit', params: { id: galleryResource.id } });
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };
        const updateGallery = async (gallery: Omit<GalleryAttributes, 'slug'> & { slug: string | undefined }) => {
            if (
                JSON.stringify(gallery) === JSON.stringify(galleries.value.find(u => u.id === gallery.id))
            ) {
                toast.info('No changes detected.');
                return;
            }

            if (galleries.value.find(u => u.id === gallery.id)!.slug === gallery.slug) {
                delete gallery.slug;
            }

            await apiFetch<GalleryAttributes>('galleries/' + gallery.id, {
                method: 'PATCH',
                body: gallery
            })
                .then(galleryResource => {
                    galleries.value = galleries.value.map(g => g.id === gallery.id ? galleryResource : g);
                    toast.success('Gallery updated!');
                    draftGallery.value = null;
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };
        const deleteGallery = async (gallery: GalleryAttributes) => {
            await apiFetch('galleries/' + gallery.id, { method: 'DELETE' })
                .then(() => {
                    galleries.value = galleries.value.filter(u => u.id !== gallery.id);
                    toast.success('Gallery deleted!');
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };

        return {
            galleries,
            draftDialog,
            deleteGallery,
            draftGallery,
            storeGallery,
            updateGallery,
            createGallery
        };
    }
});
</script>
