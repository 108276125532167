<template>
    <div class="card bg-base-100 shadow">
        <div v-show="loading" class="absolute inset-0 bg-black opacity-5 backdrop-blur-sm" />
        <span v-show="loading"
              class="loading loading-spinner loading-lg absolute
                             left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-primary" />

        <div class="card-body">
            <h2 class="card-title">
                Settings
            </h2>

            <div class="space-y-2">
                <div>
                    <label class="label inline-flex gap-1" for="utilityCosts">
                        Average Utility Costs <span class="text-sm text-base-content/50">/ hour</span>
                    </label>
                    <div class="join w-full">
                        <label for="utilityCosts" class="join-item select-none border bg-base-300 px-4 py-2.5">
                            £
                        </label>
                        <input id="utilityCosts"
                               v-model="utilityCosts"
                               type="number"
                               class="input join-item input-bordered grow bg-base-200/30"
                               step="0.01"
                               min="0">
                    </div>
                </div>
                <div>
                    <label class="label inline-flex" for="hourlyRate">
                        Hourly Rate
                    </label>
                    <div class="join w-full">
                        <label for="hourlyRate" class="join-item select-none border bg-base-300 px-4 py-2.5">
                            £
                        </label>
                        <input id="hourlyRate"
                               v-model="hourlyRate"
                               type="number"
                               class="input join-item input-bordered grow bg-base-200/30"
                               step="0.01"
                               min="0">
                    </div>
                </div>

                <div class="flex w-full justify-center">
                    <button class="btn btn-outline btn-primary" @click="$refs.passwordModal?.showModal()">
                        Change Password
                    </button>
                </div>
            </div>

            <dialog ref="passwordModal" class="modal">
                <form method="dialog" class="modal-box">
                    <div v-show="loading" class="absolute inset-0 bg-black opacity-5 backdrop-blur-sm" />
                    <span v-show="loading"
                          class="loading loading-spinner loading-lg absolute
                             left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-primary" />

                    <div class="form-control w-full items-start">
                        <label for="oldPassword" class="label">
                            Old password
                        </label>
                        <input id="oldPassword"
                               v-model="oldPassword"
                               class="input input-bordered w-full"
                               type="password">
                    </div>
                    <div class="form-control w-full items-start">
                        <label class="label inline-flex" for="password">
                            New Password
                        </label>
                        <input id="password"
                               v-model="password"
                               class="input input-bordered w-full"
                               type="password">
                    </div>
                    <div class="form-control w-full items-start">
                        <label class="label inline-flex" for="passwordConfirmation">
                            Confirm New Password
                        </label>
                        <input id="passwordConfirmation"
                               v-model="passwordConfirmation"
                               class="input input-bordered w-full"
                               type="password">
                    </div>
                    <div class="modal-action">
                        <button class="btn btn-primary"
                                :disabled="!(oldPassword &&
                                    password &&
                                    passwordConfirmation === password &&
                                    oldPassword !== password)"
                                @click="updatePassword">
                            Update
                        </button>
                    </div>
                </form>
                <form method="dialog" class="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>

            <div class="card-actions justify-end">
                <button class="btn btn-primary" :disabled="!Object.keys(changes).length" @click="update">
                    Update
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import parseError from '~/utils/parseError';
import { useToast } from 'vue-toastify';
import useAuthStore from '~/stores/auth';
import apiFetch from '~/utils/apiFetch';
import type { UserAttributes } from '~/types';

export default defineComponent({
    name: 'Settings',

    setup: () => {
        definePageMeta({ middleware: 'auth' });
        const authStore = useAuthStore();
        const loading = ref(false);
        const utilityCosts = ref(authStore.user!.utilityCosts);
        const hourlyRate = ref(authStore.user!.hourlyRate);
        const oldPassword = ref('');
        const password = ref('');
        const passwordConfirmation = ref('');
        const changes = computed(() => {
            const changedValues: Record<string, any> = {};

            if (utilityCosts.value !== authStore.user!.utilityCosts) {
                changedValues.utilityCosts = utilityCosts.value;
            }

            if (hourlyRate.value !== authStore.user!.hourlyRate) {
                changedValues.hourlyRate = hourlyRate.value;
            }

            return changedValues;
        });
        const toast = useToast();

        const updatePassword = async () => {
            loading.value = true;
            await apiFetch<UserAttributes>(`users/${authStore.user!.id}`, {
                method: 'PATCH',
                body: {
                    oldPassword: oldPassword.value,
                    password: password.value,
                    passwordConfirmation: passwordConfirmation.value
                }
            })
                .then(userResource => {
                    authStore.user = userResource;
                    toast.success('Password updated!');
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage))
                .finally(() => {
                    oldPassword.value = password.value = passwordConfirmation.value = '';
                    loading.value = false;
                });
        };
        const update = async () => {
            if (!Object.keys(changes.value).length) return;

            loading.value = true;
            await apiFetch<UserAttributes>(`users/${authStore.user!.id}`, {
                method: 'PATCH',
                body: changes.value
            })
                .then(userResource => {
                    authStore.user = userResource;
                    toast.success('Settings updated!');
                    return;
                })
                .catch(error => toast.error(parseError(error).errorMessage))
                .finally(() => loading.value = false);
        };

        return {
            loading,
            utilityCosts,
            hourlyRate,
            oldPassword,
            password,
            passwordConfirmation,
            changes,
            update,
            updatePassword
        };
    }
});
</script>
