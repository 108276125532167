<template>
    <div class="min-h-screen">
        <LazyLoader ref="loader" class="text-primary" inherit-color />
        <NuxtLayout>
            <main class="flex grow flex-col">
                <NuxtPage />
            </main>
        </NuxtLayout>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref, watch } from 'vue';
import { description, loaderKey, qAndA, title } from '~/fixtures';
import {
    useSeoMeta,
    defineLocalBusiness,
    defineWebSite,
    useSchemaOrg,
    defineQuestion,
    defineBreadcrumb,
    defineOrganization
} from '#imports';
import useProductStore from '~/stores/products';
import type { LoaderMethods } from '~/types';
import { usePreferredDark } from '@vueuse/core';
import useDarkMode from '~/composables/useDarkMode';
import { withBase, withoutTrailingSlash } from 'ufo';
import { title as titleCase } from '@upfrontjs/framework';
import { useRecaptchaProvider } from 'vue-recaptcha';
import environment from '~/utils/environment';
import type { ImageSimple, PostalAddressSimple } from '@unhead/schema-org';

export default defineComponent({
    name: 'App',

    setup: () => {
        if (environment('production')) {
            useRecaptchaProvider();
        }

        const loader = ref<LoaderMethods>();
        provide(loaderKey, loader);
        const productStore = useProductStore();
        let timeout: ReturnType<typeof setTimeout>;
        const router = useRouter();
        const config = useRuntimeConfig();
        const prefersDark = usePreferredDark();
        const darkMode = useDarkMode();
        darkMode.value = prefersDark.value;
        const breadcrumbs = computed(() => {
            if (router.currentRoute.value.fullPath === '/') {
                return [{
                    name: 'Home',
                    item: '/'
                }];
            }

            const parts = router.currentRoute.value.fullPath.split('/');

            return parts.map((part, i) => {
                return {
                    name: i === 0 ? 'Home' : titleCase(part),
                    item: i === 0 ? '/' : parts.slice(0, i + 1).join('/')
                };
            });
        });

        const image = computed(() => {
            if (productStore.product?.images.length) {
                // todo update this to weserve
                return productStore.product.images.at(0)!.source;
            }

            return '/images/logo.jpg';
        });
        const url = computed(() => withBase(router.currentRoute.value.fullPath, config.public.siteUrl));
        const canonicalUrl = computed(
            () => withoutTrailingSlash(withBase(router.currentRoute.value.path, config.public.siteUrl))
        );

        const logo: ImageSimple = {
            url: 'images/logo.jpg',
            contentUrl: withBase('images/logo.jpg', config.public.siteUrl),
            width: 1137,
            height: 1137,
            inLanguage: 'en-GB',
            caption: title + '\' Logo'
        };
        const address: PostalAddressSimple = {
            addressCountry: 'GB',
            addressLocality: 'Horsford',
            addressRegion: 'Norfolk',
            postalCode: 'NR10 3DT',
            streetAddress: '9 Greenpark Road'
        };

        useSeoMeta({
            themeColor: '#D8A595',
            msapplicationTileColor: '#da532c',
            appleMobileWebAppTitle: title,
            applicationName: title,
            colorScheme: 'light',
            google: 'nositelinkssearchbox',
            robots: {
                index: true,
                follow: true,
                all: true,
                nositelinkssearchbox: false,
                nosnippet: false
            },
            ogType: 'website',
            ogImage: image,
            ogUrl: url,
            ogImageHeight: 1137,
            ogImageWidth: 1137,
            ogImageAlt: title + '\' Logo',
            ogImageUrl: image,
            ogImageType: 'image/jpeg',
            ogLocale: 'en_GB',
            ogSiteName: 'Cina Delicious Cakes',
            ogImageSecureUrl: image,
            ogDeterminer: '',
            twitterCard: 'summary',
            twitterImage: image,
            twitterImageAlt: title + '\' Logo'
        });
        useSchemaOrg([
            defineLocalBusiness({
                openingHoursSpecification: [
                    {
                        dayOfWeek: 'Monday',
                        opens: '00:00',
                        closes: '00:00'
                    },
                    {
                        dayOfWeek: 'Tuesday',
                        opens: '00:00',
                        closes: '00:00'
                    },
                    {
                        dayOfWeek: 'Wednesday',
                        opens: '00:00',
                        closes: '00:00'
                    },
                    {
                        dayOfWeek: 'Thursday',
                        opens: '00:00',
                        closes: '00:00'
                    },
                    {
                        dayOfWeek: 'Friday',
                        opens: '00:00',
                        closes: '00:00'
                    },
                    {
                        dayOfWeek: 'Saturday',
                        opens: '10:00',
                        closes: '17:00'
                    },
                    {
                        dayOfWeek: 'Sunday',
                        opens: '10:00',
                        closes: '17:00'
                    }
                ],
                currenciesAccepted: 'GBP',
                priceRange: '££',
                email: 'cinanorwich@gmail.com'
            }),
            defineWebSite({
                name: title,
                url: config.public.siteUrl,
                description: description,
                image: logo,
                inLanguage: 'en-GB'
            }),
            ...qAndA.map(({ question, answer }) => {
                return defineQuestion({
                    name: question,
                    acceptedAnswer: {
                        text: answer
                    }
                });
            }),
            defineBreadcrumb({
                itemListElement: breadcrumbs.value
            }),
            defineOrganization({
                name: title,
                sameAs: [
                    'https://www.facebook.com/CinaCDC',
                    'https://www.instagram.com/cina_delicious_cakes/',
                    'https://www.tiktok.com/@cinasdeliciouscakes'
                ],
                address,
                logo
            })
        ]);
        useHead({
            link: [
                {
                    rel: 'canonical',
                    // google might not be able to tell what is the original url if a query string is present
                    href: canonicalUrl
                }
            ]
        });

        const nuxtApp = useNuxtApp();
        nuxtApp.hook('page:start', () => {
            timeout = setTimeout(() => {
                clearTimeout(timeout);
                loader.value?.on();
            }, 50);
        });

        nuxtApp.hook('page:finish', () => {
            clearTimeout(timeout);
            loader.value?.off();
        });

        if (import.meta.client) {
            watch(
                () => darkMode.value,
                newValue => document.body.dataset.theme = newValue ? 'dark' : 'light',
                { immediate: true }
            );
            watch(
                () => prefersDark.value,
                newValue => darkMode.value = newValue
            );
        }

        return {
            loader,
            darkMode
        };
    }
});
</script>
