import { defineNuxtPlugin } from '#app';
import { init, replayIntegration, browserTracingIntegration } from '@sentry/vue';
import type { Router } from 'vue-router';

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    setup: nuxt => {
        const { enabled, dsn, tracesSampleRate } = nuxt.$config.public.sentry;
        const appUrl = nuxt.$config.public.siteUrl;

        init({
            app: nuxt.vueApp,
            enabled,
            environment: environment(),
            dsn,
            integrations: [
                browserTracingIntegration({
                    router: nuxt.$router as Router,
                    shouldCreateSpanForRequest: (url: string) => url.includes(appUrl) || url.includes('localhost')
                }),
                replayIntegration({
                    stickySession: true,
                    maskAllText: false,
                    maskAllInputs: false,
                    blockAllMedia: false
                })
            ],
            replaysOnErrorSampleRate: 0.5,
            replaysSessionSampleRate: tracesSampleRate,
            tracesSampleRate,
            logErrors: true
        });
    }
});
