<template>
    <div class="card bg-base-100 shadow-xl">
        <div class="card-body">
            <div class="card-title justify-between">
                <h1 class="flex items-center space-x-2">
                    <span>
                        {{ draftProduct.name }}
                        <TransitionFade :duration="100">
                            <small v-if="productStore.product?.inDraft" class="text-sm text-gray-400">
                                (In Draft)
                            </small>
                        </TransitionFade>
                    </span>
                </h1>
                <div class="flex flex-wrap items-center gap-2">
                    <div class="form-control">
                        <label class="label cursor-pointer gap-2">
                            <input v-model="published" type="checkbox" class="checkbox">
                            <span class="label-text">Published</span>
                        </label>
                    </div>
                    <NuxtLink
                        :to="{ name: 'admin-products-id-edit-variations', params: { id: productStore.productId } }">
                        <button class="btn btn-sm sm:btn-md">
                            Variations
                            <Icon name="arrowRight" class="hidden sm:inline-block" />
                        </button>
                    </NuxtLink>
                </div>
            </div>

            <div role="form" class="flex flex-col justify-between space-y-6">
                <div class="space-y-2">
                    <div class="flex flex-col justify-between gap-2 lg:!flex-row">
                        <div class="form-control items-start lg:w-1/2">
                            <label class="label justify-start" for="product-name">
                                Name <span class="text-red-600">*</span>
                            </label>
                            <input id="product-name"
                                   v-model="draftProduct.name"
                                   type="text"
                                   autofocus
                                   placeholder="The name of the product"
                                   class="input input-bordered w-full bg-base-200/30">
                        </div>
                        <div class="form-control items-start lg:w-1/2">
                            <label class="label inline-flex" for="product-slug">
                                Url slug
                            </label>
                            <input id="product-slug"
                                   v-model="draftProduct.slug"
                                   type="text"
                                   placeholder="Custom url to display"
                                   class="input input-bordered w-full bg-base-200/30">
                        </div>
                    </div>
                    <div class="flex flex-col justify-between gap-2 sm:flex-row">
                        <div class="form-control w-full items-start md:w-1/2">
                            <label class="label" for="type">
                                Type <span class="text-red-600">*</span>
                            </label>
                            <select id="type"
                                    v-model="draftProduct.type"
                                    class="select select-bordered w-full bg-base-200/30"
                                    placeholder="The category the product belongs to.">
                                <option v-for="type in productTypes" :key="type" v-text="type" />
                            </select>
                        </div>
                        <div class="form-control w-full items-start md:w-1/2">
                            <label class="label inline-flex gap-1" for="product-slug">
                                Shelf Life <small>(in days)</small>
                            </label>
                            <input id="product-slug"
                                   v-model="draftProduct.shelfLife"
                                   type="number"
                                   min="1"
                                   max="65535"
                                   placeholder="Days before the product expires"
                                   class="input input-bordered w-full bg-base-200/30">
                        </div>
                    </div>
                    <div class="form-control items-start">
                        <label class="label" for="shortDescription">
                            Short Description <span class="text-red-600">*</span>
                        </label>
                        <textarea id="shortDescription"
                                  v-model="draftProduct.shortDescription"
                                  class="textarea textarea-bordered textarea-sm h-24 max-h-48
                                         w-full bg-base-200/30 md:textarea-md"
                                  placeholder="The short description of the product..." />
                    </div>
                    <div class="form-control items-start">
                        <label class="label" for="description">
                            Description
                        </label>
                        <TipTapEditor id="description"
                                      v-model="draftProduct.description"
                                      placeholder="The description/story of the product..." />
                    </div>
                    <div class="form-control items-start">
                        <label class="label" for="instructions">
                            Instructions
                        </label>
                        <TipTapEditor id="instructions"
                                      v-model="draftProduct.instructions"
                                      placeholder="The instructions for making this product." />
                    </div>
                </div>

                <TransitionExpand>
                    <div v-show="images.length">
                        <p class="text-color mb-1.5">
                            Images
                        </p>
                        <Sortable :list="images"
                                  tag="ul"
                                  :options="{ animation: 150, group: 'images' }"
                                  class="flex flex-wrap items-center justify-evenly gap-4 rounded border
                                     border-base-300 bg-base-200/30 px-4 py-2 sm:px-6 sm:py-4"
                                  item-key="id"
                                  @end="onSortEnd">
                            <template #item="{ element, index }">
                                <Transition appear name="list">
                                    <li v-if="!element.transitionOut" class="avatar indicator">
                                        <div class="indicator-item !overflow-visible">
                                            <button class="btn btn-circle btn-error btn-xs"
                                                    @click="deleteImage(element.id)">
                                                <Icon name="close" size="16" />
                                            </button>
                                        </div>
                                        <span class="badge indicator-item badge-secondary
                                                 indicator-center indicator-middle">
                                            {{ index + 1 }}
                                        </span>
                                        <div class="size-20 rounded-lg">
                                            <NuxtImg :src="element.source" />
                                        </div>
                                    </li>
                                </Transition>
                            </template>
                        </Sortable>
                    </div>
                </TransitionExpand>
                <ImageUploader @uploaded="retain" />
            </div>

            <div class="card-actions justify-end">
                <button class="btn btn-success relative"
                        :disabled="!(hasChanges || imageOrderHasChanged)"
                        @click="save">
                    <span :class="{ 'opacity-0': loading }" class="transition-opacity">Save</span>
                    <TransitionFade>
                        <span v-if="loading"
                              class="loading loading-spinner loading-sm
                                     absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </TransitionFade>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import type { ProductAttributes, ImageAttributes } from '~/types';
import useProductStore from '~/stores/products';
import parseError from '~/utils/parseError';
import { useToast } from 'vue-toastify';
import { useLoader } from '~/composables';
import { Sortable } from 'sortablejs-vue3';
import type { SortableEvent } from 'sortablejs';
import apiFetch from '~/utils/apiFetch';
import { TransitionFade, TransitionExpand } from '@morev/vue-transitions';
import useProductTypes from '~/composables/useProductTypes';
import getChanges from '~/utils/getChanges';
import ImageUploader from '~/components/admin/ImageUploader.vue';
import TipTapEditor from '~/components/admin/TipTapEditor.vue';

export default defineComponent({
    name: 'Index',

    components: {
        TipTapEditor,
        ImageUploader,
        Sortable,
        TransitionFade,
        TransitionExpand
    },

    setup: () => {
        const productStore = useProductStore();
        const toast = useToast();
        const loader = useLoader();
        const loading = ref(false);
        const fileInput = ref<HTMLInputElement>();

        definePageMeta({ middleware: ['product-exists', 'auth'] });

        const draftProduct = ref<ProductAttributes>({
            ...productStore.product!,
            instructions: productStore.product?.instructions ?? ''
        });
        const images = ref(
            // already sorted after receiving from the server
            productStore.product!.images.map(image => ({ id: image.id, source: image.source }))
        );
        const imageOrderHasChanged = computed(() => {
            const currentIds = images.value.map(image => image.id);
            const originalIds = productStore.product?.images.map(image => image.id!) ?? [];

            return currentIds.join(',') !== originalIds.join(',');
        });
        // todo - maybe a confirmation modal when updating this
        const published = computed({
            get: () => !draftProduct.value.inDraft,
            set: val => {
                draftProduct.value.inDraft = !val;
            }
        });
        const changes = computed(() => {
            if (!productStore.product) {
                return {};
            }

            const changes = getChanges(productStore.product, draftProduct.value);

            Object.keys(changes).forEach(key => {
                if (changes[key] === '' && productStore.product![key] === null) {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete changes[key];
                }

                if (key === 'variations') {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete changes[key];
                }
            });

            return changes;
        });
        const hasChanges = computed(() => Object.keys(changes.value).length);

        const deleteImage = async (imageId: ImageAttributes['id']) => {
            if (images.value.length === 1 && !draftProduct.value.inDraft) {
                toast.error('A published product must have at least one image.');
                return;
            }

            await apiFetch(`products/${draftProduct.value.id}/images`, {
                method: 'DELETE',
                body: { imageId }
            })
                .catch(reason => {
                    toast.error(parseError(reason).errorMessage);
                    throw reason;
                });

            // remove the image from the product in the store
            productStore.products = productStore.products
                .map(p => {
                    if (p.id === draftProduct.value.id) {
                        p.images = p.images.filter(i => i.id !== imageId);
                    }

                    return p;
                });

            // ensure the images are kept up to date from the store
            images.value = productStore.product!.images
                .map(image => ({
                    id: image.id,
                    source: image.source
                }));

            // update the draft product images too (reuses the above store update as this is a getter)
            draftProduct.value.images = productStore.product!.images;
        };

        const onSortEnd = (event: SortableEvent) => {
            const image = images.value[event.oldIndex!];
            images.value.splice(event.oldIndex!, 1);
            images.value.splice(event.newIndex!, 0, image);
        };
        // todo - if image order is changed it might need to be saved twice (maybe when it gets uploaded)
        const save = async () => {
            if (!images.value.length && changes.value.inDraft === false) {
                toast.error('You cannot publish a product when it has no images.');
                return;
            }

            const promises: Promise<void>[] = [];

            if (imageOrderHasChanged.value) {
                promises.push(
                    apiFetch<ImageAttributes[]>(`products/${draftProduct.value.id}/images/order`, {
                        method: 'POST',
                        body: {
                            images: images.value.map((image, index) => ({
                                id: image.id,
                                order: index + 1
                            }))
                        }
                    })
                        .then(imgs => {
                            const sortedImgs = imgs.sort((a, b) => a.meta.order - b.meta.order);

                            productStore.products = productStore.products
                                .map(p => {
                                    if (p.id === draftProduct.value.id) {
                                        p.images = sortedImgs;
                                    }

                                    return p;
                                });

                            images.value = sortedImgs
                                .map(image => ({
                                    id: image.id,
                                    source: image.source
                                }));
                            return;
                        })
                );
            }

            if (hasChanges.value) {
                promises.push(
                    apiFetch<ProductAttributes>(
                        `products/${productStore.productId!}`,
                        {
                            method: 'PATCH',
                            body: changes.value
                        }
                    )
                        .then(product => {
                            productStore.products = productStore.products.map(p => p.id === product.id ? product : p);
                            draftProduct.value = { ...product };
                            return;
                        })
                );
            }

            if (promises.length) {
                loading.value = true;
                loader?.on();
                await Promise.all(promises)
                    .catch(reason => {
                        const handler = parseError(reason);

                        toast.error(handler.errorMessage);
                        throw reason;
                    })
                    .then(() => {
                        toast.success(draftProduct.value.name + ' updated!');
                        return;
                    })
                    .finally(() => {
                        loading.value = false;
                        loader?.off();
                    });
            }
        };
        const retain = (files: { location: string; width: number; height: number; size: number }[]) => {
            files.forEach(file => {
                void apiFetch<ImageAttributes>(
                    `/products/${draftProduct.value.id}/images`,
                    { method: 'POST', body: file }
                )
                    .then(image => {
                        productStore.products = productStore.products
                            .map(p => {
                                if (p.id === draftProduct.value.id) {
                                    p.images.push(image);
                                }

                                return p;
                            });

                        // ensure the images are kept up to date
                        images.value = productStore.product!.images
                            .map(image => ({
                                id: image.id,
                                source: image.source
                            }));

                        return;
                    });
            });
        };

        return {
            productStore,
            save,
            draftProduct,
            hasChanges,
            productTypes: useProductTypes(),
            published,
            images,
            onSortEnd,
            imageOrderHasChanged,
            deleteImage,
            loading,
            fileInput,
            retain
        };
    }
});
</script>

<style>
.list-enter-active,
.list-leave-active {
    transition: all 0.6s ease;
    border-bottom: none !important;
}
</style>
