import { withBase } from 'ufo';

export const hasCSRFToken = (): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return globalThis.document?.cookie.includes('XSRF-TOKEN');
};

export const getCSRFToken = (): string | null => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (globalThis.document?.cookie) {
        const match = /XSRF-TOKEN=((.*?)[;,]|.*$)/.exec(document.cookie);
        const token = match ? match[0] : null;

        if (token) {
            return decodeURIComponent(token.slice('XSRF-TOKEN='.length));
        }
    }

    return null;
};

export const resetCSRFToken = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!globalThis.document) return;

    await fetch(withBase('csrf-cookie', import.meta.env.VITE_API_URL), { credentials: 'include' });
};
