<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="card bg-base-100 shadow-xl">
        <div class="card-body">
            <table class="table table-xs table-fixed sm:table-md">
                <thead>
                    <tr>
                        <td class="max-w-[50%]" colspan="2">
                            <h1 class="inline-flex w-full items-center justify-between gap-2">
                                <NuxtLink :to="{ name: 'products-slug', params: { slug: productStore.product!.slug } }">
                                    <Icon name="openInNew" size="20" />
                                </NuxtLink>
                                <span class="truncate text-sm sm:text-xl" :title="productStore.product!.name">
                                    {{ productStore.product.name }}
                                </span>
                                <NuxtLink :to="{
                                              name: 'admin-products-id-edit',
                                              params: { id: productStore.productId }
                                          }"
                                          class="transition-colors hover:text-gray-500">
                                    <Icon name="edit" size="20" />
                                </NuxtLink>
                            </h1>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="font-bold">
                            Type
                        </td>
                        <td>{{ productStore.product.type }}</td>
                    </tr>
                    <tr>
                        <td class="font-bold">
                            Slug
                        </td>
                        <td>{{ productStore.product.slug }}</td>
                    </tr>
                    <tr>
                        <td class="font-bold">
                            Published
                        </td>
                        <td>{{ productStore.product.inDraft ? 'No' : 'Yes' }}</td>
                    </tr>
                    <tr>
                        <td class="font-bold">
                            Short Description
                        </td>
                        <td>{{ productStore.product.shortDescription }}</td>
                    </tr>
                    <tr>
                        <td class="font-bold">
                            Description
                        </td>
                        <td v-if="productStore.product!.description">
                            <button class="btn btn-primary btn-xs mx-auto inline sm:btn-md"
                                    @click="content = productStore.product!.description; $refs.modal.showModal()">
                                View
                            </button>
                        </td>
                        <td v-else class="select-none italic text-base-content/40">
                            Not set
                        </td>
                    </tr>
                    <tr>
                        <td class="font-bold">
                            Instructions
                        </td>
                        <td v-if="productStore.product!.instructions">
                            <button class="btn btn-primary btn-xs mx-auto inline sm:btn-md"
                                    @click="content = productStore.product!.instructions; $refs.modal.showModal()">
                                View
                            </button>
                        </td>
                        <td v-else class="select-none italic text-base-content/40">
                            Not set
                        </td>
                    </tr>
                    <tr>
                        <td class="font-bold">
                            Variations
                        </td>
                        <td>{{ productStore.product.variations.length }}</td>
                    </tr>
                    <tr>
                        <td class="font-bold">
                            Shelf Life <small>(in days)</small>
                        </td>
                        <td :class="{ 'select-none italic text-base-content/40': !productStore.product?.shelfLife }">
                            {{ productStore.product.shelfLife ?? 'Not set' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <dialog ref="modal" class="modal">
            <div class="modal-box max-w-full">
                <div class="prose" v-html="content" />
                <div class="modal-action">
                    <form method="dialog">
                        <button class="btn">
                            Close
                        </button>
                    </form>
                </div>
            </div>
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useProductStore from '~/stores/products';
import Icon from '~/components/Icon.vue';

export default defineComponent({
    name: 'Index',

    components: {
        Icon
    },

    beforeRouteEnter: (to) => {
        const id = Number(to.params.id);

        if (isNaN(id)) {
            return false;
        }
    },

    setup: () => {
        const productStore = useProductStore();
        const content = ref('');
        definePageMeta({ middleware: ['product-exists', 'auth'] });

        return {
            productStore,
            content
        };
    }
});
</script>
