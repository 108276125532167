<template>
    <div>
        <div class="card bg-base-100 shadow">
            <div class="card-body">
                <div class="card-title justify-between">
                    <h1>Packagings</h1>
                    <button class="btn btn-outline btn-primary"
                            @click="() => {createPackaging();$refs.draftDialog.showModal()}">
                        Add Packaging
                    </button>
                </div>
                <input v-if="packagingStore.packagings.length > 1"
                       v-model="search"
                       type="text"
                       placeholder="Search..."
                       class="input input-bordered my-4">
                <div class="overflow-x-auto">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Cost</th>
                                <th>Unit Amount</th>
                                <th>Unit</th>
                                <th>Unit Cost</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="packaging in packagings" :key="packaging.id">
                                <td>{{ packaging.name }}</td>
                                <td>£ {{ packaging.cost }}</td>
                                <td>{{ packaging.unitAmount }}</td>
                                <td>{{ unitStore.getDisplayName(packaging.unitId) }}</td>
                                <td>
                                    <div class="flex gap-2">
                                        <button class="btn btn-square btn-outline btn-info btn-sm"
                                                @click="() => {
                                                    draftPackaging = {...packaging};
                                                    $refs.draftDialog.showModal()
                                                }">
                                            <Icon name="edit" />
                                        </button>
                                        <button class="btn btn-square btn-outline btn-error btn-sm"
                                                @click="deletePackaging(packaging)">
                                            <Icon name="delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <dialog ref="draftDialog" class="modal">
            <form v-if="draftPackaging" method="dialog" class="modal-box space-y-2 text-base-content">
                <h3 class="text-lg font-bold">
                    {{ isNaN(draftPackaging.id) ? 'Create' : 'Update' }} Packaging
                </h3>

                <div class="form-control items-start">
                    <label class="label justify-start" for="name">
                        Name <span class="text-red-600">*</span>
                    </label>
                    <input id="name"
                           v-model="draftPackaging.name"
                           type="text"
                           autofocus
                           placeholder="The name of the packaging"
                           class="input input-bordered w-full bg-base-200/30">
                </div>

                <div class="form-control items-start">
                    <label class="label justify-start" for="cost">
                        Cost <span class="text-red-600">*</span>
                    </label>
                    <input id="cost"
                           v-model="draftPackaging.cost"
                           type="number"
                           min="0.00"
                           required
                           step="0.01"
                           pattern="^[0-9]+(\.[0-9]{1,2})?$"
                           placeholder="The cost of the packaging."
                           class="input input-bordered w-full bg-base-200/30">
                </div>

                <div class="form-control w-full items-start">
                    <label class="label" for="unit">
                        Unit<span class="text-red-600">*</span>
                    </label>
                    <select id="unit"
                            v-model="draftPackaging.unitId"
                            class="select select-bordered w-full bg-base-200/30"
                            placeholder="The category the product belongs to.">
                        <optgroup label="Absolute units">
                            <option v-for="unit in unitStore.absoluteUnits"
                                    :key="unit.id"
                                    :selected="unit.id === unit.id"
                                    :value="unit.id"
                                    v-text="unit.name ?? unit.abbreviation" />
                        </optgroup>
                        <optgroup label="Relative units">
                            <option v-for="unit in unitStore.relativeUnits"
                                    :key="unit.id"
                                    :selected="unit.id === unit.id"
                                    :value="unit.id"
                                    v-text="unit.name ?? unit.abbreviation" />
                        </optgroup>
                    </select>
                </div>

                <div class="form-control items-start">
                    <label class="label justify-start" for="unitAmount">
                        Unit Amount
                    </label>
                    <div class="w-full" :class="{ 'join': draftPackaging.unitId }">
                        <input id="unitAmount"
                               v-model="draftPackaging.unitAmount"
                               type="number"
                               min="1"
                               required
                               step="1"
                               :placeholder="`The amount of ${!isNaN(draftPackaging.unitId)
                                   ? unitStore.getDisplayName(draftPackaging.unitId, 2).toLowerCase()
                                   : 'units'} in this packaging.`"
                               class="input join-item input-bordered w-full bg-base-200/30">
                        <label v-if="!isNaN(draftPackaging.unitId)"
                               for="unitAmount"
                               class="join-item select-none border border-base-300 bg-base-300 px-4 py-2.5">
                            {{ unitStore.getDisplayName(draftPackaging.unitId, draftPackaging.unitAmount) }}
                        </label>
                    </div>
                </div>

                <div class="flex items-center justify-end gap-2">
                    <button class="btn btn-ghost"
                            type="submit"
                            @click="() =>{$refs.draftDialog.close();draftPackaging = null}">
                        Cancel
                    </button>
                    <button class="btn"
                            :class="[isNaN(draftPackaging.id) ? 'btn-success' : 'btn-info']"
                            type="submit"
                            @click="() => {
                                $refs.draftDialog.close();
                                isNaN(draftPackaging.id)
                                    ? storePackaging(draftPackaging)
                                    : updatePackaging(draftPackaging)
                            }">
                        Save
                    </button>
                </div>
            </form>
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import usePackagingStore from '~/stores/packaging';
import type { PackagingAttributes } from '~/types';
import apiFetch from '~/utils/apiFetch';
import { useToast } from 'vue-toastify';
import useUnitStore from '~/stores/unit';

export default defineComponent({
    name: 'Index',

    setup: () => {
        definePageMeta({ middleware: 'auth' });
        const packagingStore = usePackagingStore();
        const unitStore = useUnitStore();
        const toast = useToast();
        const search = ref('');
        const packagings = computed(() => {
            if (!search.value) return packagingStore.packagings;

            return packagingStore.packagings.filter(
                packaging => packaging.name.toLowerCase().includes(search.value.toLowerCase())
            );
        });
        const draftPackaging = ref<PackagingAttributes | null>(null);
        const draftDialog = ref<HTMLDialogElement>();

        const createPackaging = () => {
            draftPackaging.value = {
                id: NaN,
                cost: NaN,
                unitAmount: NaN,
                unitId: NaN,
                name: ''
            };
        };
        const storePackaging = async (packaging: PackagingAttributes) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...body } = packaging;

            await apiFetch<PackagingAttributes>('packagings', { method: 'POST', body })
                .then(packagingResource => {
                    packagingStore.packagings.push(packagingResource);
                    toast.success('Packaging created!');
                    draftPackaging.value = null;
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };
        const updatePackaging = async (packaging: PackagingAttributes) => {
            if (
                JSON.stringify(packaging) === JSON.stringify(packagingStore.packagings.find(u => u.id === packaging.id))
            ) {
                toast.info('No changes detected.');
                return;
            }

            await apiFetch<PackagingAttributes>('packagings/' + packaging.id, {
                method: 'PATCH',
                body: packaging
            })
                .then(packagingResource => {
                    packagingStore.packagings = packagingStore.packagings.map(
                        u => u.id === packaging.id ? packagingResource : u
                    );
                    toast.success('Packaging updated!');
                    draftPackaging.value = null;
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };
        const deletePackaging = async (packaging: PackagingAttributes) => {
            await apiFetch('packagings/' + packaging.id, { method: 'DELETE' })
                .then(() => {
                    packagingStore.packagings = packagingStore.packagings.filter(u => u.id !== packaging.id);
                    toast.success('Packaging deleted!');
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };

        return {
            packagingStore,
            createPackaging,
            storePackaging,
            updatePackaging,
            deletePackaging,
            packagings,
            search,
            draftPackaging,
            draftDialog,
            unitStore
        };
    }
});
</script>
