<template>
    <div>
        <div class="card bg-base-100 shadow">
            <div class="card-body">
                <div class="card-title justify-between">
                    <h1>Units</h1>
                    <button class="btn btn-outline btn-primary"
                            @click="() => {createUnit();$refs.draftDialog.showModal()}">
                        Add Unit
                    </button>
                </div>
                <input v-if="unitStore.units.length > 1"
                       v-model="search"
                       type="text"
                       placeholder="Search..."
                       class="input input-bordered my-4">
                <div class="overflow-x-auto">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Abbreviation</th>
                                <th>Name</th>
                                <th>Reference</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="unit in units" :key="unit.abbreviation">
                                <td>{{ unit.abbreviation }}</td>
                                <td>
                                    <template v-if="unit.name">
                                        {{ unit.name }}
                                    </template>
                                    <span v-else class="select-none text-base-content/50">Not set</span>
                                </td>
                                <td>{{ unit.isRelative! ? 'Relative' : 'Absolute' }}</td>
                                <td>
                                    <div class="flex gap-2">
                                        <button class="btn btn-square btn-outline btn-info btn-sm"
                                                @click="() => {draftUnit = unit; $refs.draftDialog.showModal()}">
                                            <Icon name="edit" />
                                        </button>
                                        <button class="btn btn-square btn-outline btn-error btn-sm"
                                                @click="deleteUnit(unit)">
                                            <Icon name="delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <dialog ref="draftDialog" class="modal">
            <form v-if="draftUnit" method="dialog" class="modal-box space-y-2 text-base-content">
                <h3 class="text-lg font-bold">
                    {{ isNaN(draftUnit.id) ? 'Create' : 'Update' }} Unit
                </h3>

                <div class="form-control items-start">
                    <label class="label justify-start" for="abbreviation">
                        Abbreviation <span class="text-red-600">*</span>
                    </label>
                    <input id="abbreviation"
                           v-model="draftUnit.abbreviation"
                           type="text"
                           autofocus
                           placeholder="The short form of the unit"
                           class="input input-bordered w-full bg-base-200/30">
                </div>

                <div class="form-control items-start">
                    <label class="label justify-start" for="name">
                        Name
                    </label>
                    <input id="name"
                           v-model="draftUnit.name"
                           type="text"
                           autofocus
                           placeholder="The display name of the unit"
                           class="input input-bordered w-full bg-base-200/30">
                </div>

                <label class="label mx-auto inline-flex cursor-pointer gap-2">
                    <input v-model="draftUnit.isRelative" type="checkbox" class="checkbox">
                    <span class="label-text font-bold">Relative</span>
                </label>

                <div class="flex items-center justify-end gap-2">
                    <button class="btn btn-ghost"
                            type="submit"
                            @click="() =>{$refs.draftDialog.close();draftUnit = null}">
                        Cancel
                    </button>
                    <button class="btn"
                            :class="[isNaN(draftUnit.id) ? 'btn-success' : 'btn-info']"
                            type="submit"
                            @click="() => {
                                $refs.draftDialog.close();
                                isNaN(draftUnit.id) ? storeUnit(draftUnit) : updateUnit(draftUnit)
                            }">
                        Save
                    </button>
                </div>
            </form>
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import useUnitStore from '~/stores/unit';
import type { UnitAttributes } from '~/types';
import apiFetch from '~/utils/apiFetch';
import { useToast } from 'vue-toastify';

export default defineComponent({
    name: 'Index',

    setup: () => {
        definePageMeta({ middleware: 'auth' });
        const unitStore = useUnitStore();
        const toast = useToast();
        const search = ref('');
        const units = computed(() => {
            if (!search.value) return unitStore.units;

            return unitStore.units.filter(unit => {
                return unit.name?.toLowerCase().includes(search.value.toLowerCase())
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    || unit.abbreviation.toLowerCase().includes(search.value.toLowerCase());
            });
        });
        const draftUnit = ref<UnitAttributes | null>(null);
        const draftDialog = ref<HTMLDialogElement>();

        const createUnit = () => {
            draftUnit.value = {
                id: NaN,
                abbreviation: '',
                isRelative: false,
                name: null
            };
        };
        const storeUnit = async (unit: UnitAttributes) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...body } = unit;

            await apiFetch<UnitAttributes>('units', { method: 'POST', body })
                .then(unitResource => {
                    unitStore.units.push(unitResource);
                    toast.success('Unit created!');
                    draftUnit.value = null;
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };
        const updateUnit = async (unit: UnitAttributes) => {
            if (JSON.stringify(unit) === JSON.stringify(unitStore.units.find(u => u.id === unit.id))) {
                toast.info('No changes detected.');
                return;
            }

            await apiFetch<UnitAttributes>('units/' + unit.id, {
                method: 'PATCH',
                body: unit
            })
                .then(unitResource => {
                    unitStore.units = unitStore.units.map(u => u.id === unit.id ? unitResource : u);
                    toast.success('Unit updated!');
                    draftUnit.value = null;
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };
        const deleteUnit = async (unit: UnitAttributes) => {
            await apiFetch('units/' + unit.id, { method: 'DELETE' })
                .then(() => {
                    unitStore.units = unitStore.units.filter(u => u.id !== unit.id);
                    toast.success('Unit deleted!');
                    return;
                })
                .catch(reason => toast.error(parseError(reason).errorMessage));
        };

        return {
            unitStore,
            createUnit,
            storeUnit,
            updateUnit,
            deleteUnit,
            units,
            search,
            draftUnit,
            draftDialog
        };
    }
});
</script>
