<template>
    <div>
        <div class="card bg-base-100 shadow-xl">
            <div class="card-body">
                <div class="card-title justify-between">
                    <h1>Ingredients</h1>
                    <button class="btn btn-outline btn-primary" @click="ingredientEditor?.openFresh()">
                        <Icon name="plus" />
                    </button>
                </div>
                <input v-if="ingredientStore.ingredients.length > 1"
                       v-model="search"
                       type="text"
                       placeholder="Search..."
                       class="input input-bordered my-4">
                <div class="overflow-x-auto" style="max-height: 65vh">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Cost</th>
                                <th>Quantity</th>
                                <th>Products Count</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ingredient in ingredients" :key="ingredient.id" class="hover">
                                <td>
                                    {{ ingredient.name }}
                                </td>
                                <td>£{{ ingredient.cost }}</td>
                                <td>{{ amountDisplayValue(ingredient) }}</td>
                                <td>{{ ingredient.productsCount }}</td>
                                <td>
                                    <div class="flex w-full justify-center gap-1">
                                        <button :title="`Edit ${ingredient.name}`"
                                                class="btn btn-square btn-outline btn-info btn-sm"
                                                @click="ingredientEditor?.editIngredient(ingredient)">
                                            <Icon name="edit" />
                                        </button>
                                        <button :title="`Delete ${ingredient.name}`"
                                                :disabled="ingredient.productsCount > 0"
                                                class="btn btn-square btn-outline btn-error btn-sm"
                                                @click="() => {
                                                    ingredientToDelete = ingredient;
                                                    $refs.deleteConfirm.showModal()
                                                }">
                                            <Icon name="delete" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!ingredients.length">
                                <td colspan="5">
                                    <div class="mx-auto flex flex-col items-center justify-center gap-2">
                                        <p class="select-none font-bold text-base-content/50">
                                            No ingredients found.
                                        </p>
                                        <br>
                                        <button class="btn btn-primary" @click="ingredientEditor?.openFresh()">
                                            Add ingredient
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <dialog ref="deleteConfirm" class="modal text-base-content">
            <form method="dialog" class="modal-box">
                <h3 class="text-lg font-bold">
                    Confirm delete
                </h3>
                <p class="py-6">
                    Are you sure you want to delete
                    <span v-if="ingredientToDelete" class="font-bold">{{ ingredientToDelete.name }}</span>?
                </p>
                <div class="flex items-center justify-end gap-2">
                    <button class="btn btn-ghost" type="submit">
                        Cancel
                    </button>
                    <button class="btn btn-error" type="submit" @click="deleteIngredient">
                        Confirm
                    </button>
                </div>
            </form>
        </dialog>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import type { IngredientAttributes } from '~/types';
import { plural } from '@upfrontjs/framework';
import Icon from '~/components/Icon.vue';
import { useIngredientEditor } from '~/composables';
import useIngredientStore from '~/stores/ingredient';
import { useToast } from 'vue-toastify';
import parseError from '~/utils/parseError';
import useProductStore from '~/stores/products';
import apiFetch from '~/utils/apiFetch';
import useUnitStore from '~/stores/unit';

export default defineComponent({
    name: 'Index',

    components: { Icon },

    setup: () => {
        definePageMeta({ middleware: 'auth' });
        const ingredientEditor = ref<ReturnType<typeof useIngredientEditor>>();
        const ingredientStore = useIngredientStore();
        const unitStore = useUnitStore();
        const toast = useToast();
        const productStore = useProductStore();
        const search = ref('');
        const ingredients = computed(() => {
            if (!search.value) {
                return ingredientStore.ingredients;
            }

            const searchTerm = search.value.toLowerCase();
            return ingredientStore.ingredients.filter(ingredient => ingredient.name.toLowerCase().includes(searchTerm));
        });
        const ingredientToDelete = ref<IngredientAttributes>();

        const deleteIngredient = async () => {
            if (!ingredientToDelete.value) {
                return;
            }

            const usedIn = productStore.products
                .filter(product => product.ingredients.some(ing => ing.id === ingredientToDelete.value!.id));

            if (usedIn.length) {
                toast.error(
                    'Cannot delete ingredient when still used in products. ' +
                    `It is used in the following product${usedIn.length > 1 ? 's' : ''}: ` +
                    `${usedIn.map(product => product.name).join(', ')}.`
                );
                return;
            }

            return apiFetch(`/ingredients/${ingredientToDelete.value.id}`, { method: 'DELETE' })
                .catch(reason => {
                    const error = parseError(reason);

                    if (error.isNotFoundError) {
                        ingredientStore.ingredients = ingredientStore.ingredients
                            .filter(ing => ing.id !== ingredientToDelete.value!.id);
                        toast.success('Ingredient deleted');
                        return;
                    }

                    toast.error(error.errorMessage);
                })
                .then(() => {
                    ingredientStore.ingredients = ingredientStore.ingredients
                        .filter(ing => ing.id !== ingredientToDelete.value!.id);
                    ingredientToDelete.value = undefined;
                    toast.success('Ingredient deleted');
                    return;
                });
        };

        onMounted(() => {
            ingredientEditor.value = useIngredientEditor();
        });

        const amountDisplayValue = (ingredient: IngredientAttributes): string => {
            const ingredientUnit = unitStore.units.find(unit => unit.id === ingredient.unitId)!;

            if (ingredient.unitAmount === 1) {
                return ' ' + ingredientUnit.name;
            }

            return ' ' + plural(ingredient.name);
        };

        return {
            ingredientStore,
            ingredients,
            search,
            ingredientEditor,
            deleteIngredient,
            amountDisplayValue,
            ingredientToDelete
        };
    }
});
</script>
